import { onCleanup, onMount } from 'solid-js'
import { Engine } from '../core/engine/Engine.type'
import progressZoomQueue from '../core/progressZoomQueue'
import noop from '../ldsh/noop'

const defaultPrevFrame = 1
const defaultFirstFrame = 2

export default function bindEngineToComponent(engine: Engine) {
  let prevTimeStamp = defaultPrevFrame
  const step: FrameRequestCallback = function (timeStamp: number) {
    const frameMs = timeStamp - prevTimeStamp
    // if there is zoom, move it.
    progressZoomQueue(engine, frameMs)

    // console.log('step', timeStamp % 1000)
    // frameCount++
    // console.log('render.a', a)
    for (let index = engine.renderFns.length; index--; ) {
      // can't use LayerMeta here
      // because the whole point
      // is that each layer may
      // render at different schedules
      engine.renderFns[index](timeStamp)
    }

    // returns ID number of next animation frame
    requestAnimationFrame(rafStep)
    // console.log('render.b', b)
    prevTimeStamp = timeStamp
  }
  let rafStep: FrameRequestCallback = noop
  onMount(() => {
    rafStep = step
    // first render
    setTimeout(step, 1, defaultFirstFrame)
  })
  onCleanup(() => {
    rafStep = noop
  })
  // createMountedInverval(() => {
  //   const prev10s = startOf10s
  //   startOf10s = performance.now()
  //   // console.log('FPS:', frameCount, (startOf10s - prev10s), startOf10s, prev10s)
  //   console.log('FPS:', round(10 * ((startOf10s - prev10s) / frameCount)) / 10)
  //   frameCount = 0
  // }, 1000 * 5)
}

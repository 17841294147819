import { Key } from '@solid-primitives/keyed'
import { FaSolidArrowRight, FaSolidX } from 'solid-icons/fa'
import { Component, createEffect, createMemo, createSignal, on, Show, useContext, type Accessor, type JSX } from 'solid-js'
import { modifyMutable, produce, unwrap } from 'solid-js/store'
import calculateDraftMoveAttackEstimates from '../../lib/core/draft_move/calculateDraftMoveAttackEstimates'
import type { DraftMove } from '../../lib/core/draft_move/DraftMove.type'
import type { Engine } from '../../lib/core/engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../lib/core/engine/resetEngineToDefaultSelectedTool'
import entityDisplayName from '../../lib/core/entity/entityDisplayName'
import type { Entity } from '../../lib/core/entity/index'
import unitHasRangedAttackWeapon from '../../lib/core/entity/unitHasRangedAttackWeapon'
import unitHasUsableRangedAttackWeapon from '../../lib/core/entity/unitHasUsableRangedAttackWeapon'
import type { HasHP } from '../../lib/core/has_hp'
import type { HasWeapons } from '../../lib/core/HasWeapons'
import { SelectedToolId } from '../../lib/core/map_editor/SelectedToolId.enum'
import type { HasPlayerId } from '../../lib/core/player/has_player_id'
import { createMoveUnitAction } from '../../lib/core/state/flux/action/Game/MoveUnitAction'
import dispatchClient from '../../lib/core/state/flux/dispatchClient'
import coord from '../../lib/core/tile_position_xy/coord'
import type { PathTurnSteps_Step } from '../../lib/core/tile_position_xy/findMostEfficientPathToTileV2/PathTurnSteps_Step.type'
import findUnloadableCargoTileCollection, { type UnloadableCargoTileGroup } from '../../lib/core/tile_position_xy/findUnloadableCargoTileCollection'
import { samePosition } from '../../lib/core/tile_position_xy/samePosition'
import handlePreviewRangedAttackOptions from '../../lib/core/ui/action/PreviewRangedAttackOptions'
import { deepClone } from '../../lib/deep_clone'
import Button from '../../lib/jsx/Button'
import nullIfEmptyArray from '../../lib/ldsh/nullIfEmptyArray'
import withBusy from '../../lib/withBusy'
import type { Nullable } from '../../typescript'
import EngineContext from '../EngineContext'
import LoadingIcon from '../LoadingIcon'

const DraftMoveMenuWidget: Component = () => {
  const engine : Engine = useContext(EngineContext)

  const yourTurn = (): boolean => (engine.authPlayerId && engine.authPlayerId === engine.state.turnPlayerId) as boolean

  return (
    <Show when={yourTurn() && engine.draftMove} children={(draftMove) => {

      const lastStep = (): Nullable<PathTurnSteps_Step> => draftMove().destPositionPaths?.lastStep

      const [isBusy, setIsBusy] = createSignal<boolean>(false)

      // createEffect(() => {
      //   console.log('annexableEntity', unwrap(annexableEntity()), 'draftMove', unwrap(draftMove()))
      // })
      createEffect(() => {
        console.log('lastStep', unwrap(lastStep()))
      })

      const directAttackableEntityList = createMemo<Array<Entity & HasHP & HasPlayerId>>(() => {
        const list : Array<Entity & HasHP & HasPlayerId> = []
        lastStep()?.enemies?.forEach?.((row) => {
          if (row.canDirectAttack) {
            list.push(row.ent as Entity & HasHP & HasPlayerId)
          }
        })
        return list
      })

      const unitWithRangedAttackWeapon = createMemo<Nullable<Entity & HasWeapons>>(() => {
        const { unit } = draftMove()
        if (unitHasRangedAttackWeapon(unit)) {
          return unit
        }
      })
      const unitWithUsableRangedAttackWeapon = createMemo<Nullable<Entity & HasWeapons>>(() => {
        const unit = unitWithRangedAttackWeapon()
        if (unit && unitHasUsableRangedAttackWeapon(unit)) {
          return unit
        }
      })

      const rangedAttackableEntityList = createMemo<Array<Entity & HasHP & HasPlayerId>>(() => {
        const list : Array<Entity & HasHP & HasPlayerId> = []
        lastStep()?.enemies?.forEach?.((row) => {
          if (row.canRangedAttack) {
            list.push(row.ent as Entity & HasHP & HasPlayerId)
          }
        })
        return list
      })
      createEffect(() => {
        console.log('rangedAttackableEntityList', deepClone(rangedAttackableEntityList()))
      })

      const unloadableCargoPathGroupList = createMemo<Nullable<Array<UnloadableCargoTileGroup>>>(on(() => engine.draftMove, (draftMove : Nullable<DraftMove>) => {
        return nullIfEmptyArray(findUnloadableCargoTileCollection(engine, draftMove))
      }))

      const cssStyle = createMemo<Partial<JSX.CSSProperties>>(() => {
        return {
          position: 'fixed',
          left: '10px',
          top: '10px',
        }
      })
      return (
        <div class="cw cw-dm float-end m-2" style={cssStyle()}>
          <Key each={directAttackableEntityList()} by="id"
            children={(directAttackableEntity) => {
              return <Button
                disabled={isBusy()}
                name="direct_attack"
                class="btn btn-primary d-block"
                ref={(elem : HTMLButtonElement) => {
                  // onmouseenter/onmouseleave does not include children
                  // onmouseover/onmouseout includes children
                  elem.onmouseover = () => {
                    const targetEnt = directAttackableEntity()
                    const position = coord(targetEnt.x, targetEnt.y)
                    modifyMutable(engine, produce((engine) => {
                      const { draftMove } = engine
                      if (draftMove && !samePosition(draftMove.attackPosition, position)) {
                        draftMove.attackPosition = position
                        calculateDraftMoveAttackEstimates(engine)
                      }
                    }))
                  }
                  elem.onmouseout = () => {
                    const targetEnt = directAttackableEntity()
                    const position = coord(targetEnt.x, targetEnt.y)
                    modifyMutable(engine, produce((engine) => {
                      const { draftMove } = engine
                      if (draftMove && samePosition(draftMove.attackPosition, position)) {
                        draftMove.attackPosition = null
                        calculateDraftMoveAttackEstimates(engine)
                      }
                    }))
                  }
                }}
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  const targetEnt = directAttackableEntity()
                  const newDraftMove : DraftMove = {
                    ...draftMove(),
                    attackPosition: coord(targetEnt.x, targetEnt.y),
                    target: targetEnt,
                  }
                  engine.selectedTool = SelectedToolId.MoveUnit
                  engine.draftMove = newDraftMove
                  calculateDraftMoveAttackEstimates(engine)
                  await dispatchClient(engine, createMoveUnitAction(newDraftMove))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {` Attack ${entityDisplayName(directAttackableEntity())} `}
                <Show when={directAttackableEntityList().length === 1}
                  children={<span class="text-muted small">[d]</span>}
                />
              </Button>
            }}
          />
          <Show when={!draftMove().attackPosition && unitWithRangedAttackWeapon() && engine.selectedTool === SelectedToolId.MoveUnit}
            children={(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              rangedUnit) => {
              return <Button
                disabled={isBusy() || !unitWithUsableRangedAttackWeapon()}
                name="ranged_attack"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  handlePreviewRangedAttackOptions(engine)
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {` Ranged Attack `}
                <span class="text-muted small">[r]</span>
              </Button>
            }}
          />
          <Key each={lastStep()?.annex} by="id" children={(annexableEntity) => {
              // const annexableEntityTypeMeta = () => 
              return <Button
                disabled={isBusy()}
                name="annex"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  const newDraftMove : DraftMove = {
                    ...draftMove(),
                    annex: true,
                  }
                  engine.selectedTool = SelectedToolId.MoveUnit
                  // console.log('engine.draftMove = newDraftMove')
                  engine.draftMove = newDraftMove
                  calculateDraftMoveAttackEstimates(engine)
                  await dispatchClient(engine, createMoveUnitAction(newDraftMove))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {` Annex ${entityDisplayName(annexableEntity())} `}
                <span class="text-muted small">[a]</span>
              </Button>
            }}
          />
          <Show when={lastStep()?.merge} children={(mergableEntity) => {
              // const mergableEntityTypeMeta = () => 
              return <Button
                disabled={isBusy()}
                name="merge"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  await dispatchClient(engine, createMoveUnitAction({
                    ...draftMove(),
                    merge: mergableEntity(),
                  }))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {` Merge ${entityDisplayName(mergableEntity())} `}
                <span class="text-muted small">[m]</span>
              </Button>
            }}
          />
          <Key each={lastStep()?.pickup} by="id" children={(cargoEnt : Accessor<Entity>) => {
            return (
              <Button
                disabled={isBusy()}
                name="pickup"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  await dispatchClient(engine, createMoveUnitAction({
                    ...draftMove(),
                    pickup: cargoEnt(),
                  }))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {' Pickup '}
                <span class="text-muted small">[p]</span>
              </Button>
            )
          }} />
          <Key each={lastStep()?.taxis} by="id" children={(taxiEnt : Accessor<Entity>) => {
            return (
              <Button
                disabled={isBusy()}
                name="pickup"
                class="btn btn-primary d-block"
                onClick={withBusy(isBusy, setIsBusy, async () => {
                  await dispatchClient(engine, createMoveUnitAction({
                    ...draftMove(),
                    taxi: taxiEnt(),
                  }))
                  modifyMutable(engine, produce((engine) => {
                    resetEngineToDefaultSelectedTool(engine)
                  }))
                })}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {' Load '}
                <span class="text-muted small">[p]</span>
              </Button>
            )
          }} />
          <Show when={unloadableCargoPathGroupList()} children={(unloadableCargoPathGroupList : Accessor<Array<UnloadableCargoTileGroup>>) => {
            return <Key each={unloadableCargoPathGroupList()} by="cargo_id" children={(ucPathGroup : Accessor<UnloadableCargoTileGroup>) => {
              return <Button
                disabled={isBusy()}
                name="unload"
                class="btn btn-primary d-block"
                classList={{
                  'btn-secondary': draftMove()?.unload?.cargo_id === ucPathGroup().cargo_id
                }}
                onClick={() => {
                  // not an action, therefor no withBusy
                  // await dispatchClient(engine, createMoveUnitAction({
                  //   ...draftMove(),
                  //   unload: ucPathGroup().cargo,
                  //   unloadPosition: toCoord(ucPathGroup()),
                  // }))
                  modifyMutable(engine, produce((engine) => {
                    const { draftMove } = engine
                    if (draftMove) {
                      draftMove.unload = ucPathGroup()
                    }
                  }))
                }}
              >
                <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
                {' Unload '}
                <span class="text-muted small">[u]</span>
              </Button>
            }}/>
          }} />
          <Show when={draftMove().destPosition && !(lastStep()?.occupant)}>
            <Button
              disabled={isBusy()}
              name="commit"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                await dispatchClient(engine, createMoveUnitAction(draftMove()))
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidArrowRight/>} />
              {' Commit '}
              <span class="text-muted small">[w]</span>
            </Button>
          </Show>
          <Show when={draftMove() && !draftMove().destPosition}>
            <Button
              disabled={isBusy()}
              name="wait"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                await dispatchClient(engine, createMoveUnitAction({
                  ...draftMove(),
                  wait: true,
                }))
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidX/>} />
              {' Wait '}
              <span class="text-muted small">[w]</span>
            </Button>
            </Show>
          <Show when={draftMove()}>
            <Button
              disabled={isBusy()}
              name="cancel"
              class="btn btn-primary d-block"
              onClick={withBusy(isBusy, setIsBusy, async () => {
                modifyMutable(engine, produce((engine) => {
                  resetEngineToDefaultSelectedTool(engine)
                }))
              })}
            >
              <Show when={isBusy()} children={<LoadingIcon />} fallback={<FaSolidX/>} />
              {' Cancel '}
              <span class="text-muted small">[esc]</span>
            </Button>
          </Show>
        </div>
      )
    }} />
  )
}

export default DraftMoveMenuWidget

import { InferOutput, literal, object } from 'valibot'
import { EntityTypeMeta, entityTypeDefaults } from '../../EntityTypeMeta'
import { HasXYSchema } from '../../has_xy'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityTypeId } from '../entity_type_id.enum'
import { EntityIdSchema } from '../id.type'

const entDefaults = {
  etype_id: EntityTypeId.Sea,
  layer_id: EntityLayerId.TerrainL1,
} as const

export const SeaEntitySchema = object({
  etype_id: literal(EntityTypeId.Sea),
  layer_id: literal(EntityLayerId.TerrainL1),
  id: EntityIdSchema,
  ...HasXYSchema.entries,
})

export type SeaEntity = InferOutput<typeof SeaEntitySchema>

export const SeaEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.Sea,
  dname: 'Sea',
  layer_id: EntityLayerId.TerrainL1,
  defense: 0,
  entDefaults,
}

export function createSeaEntity(id: number, x: number, y: number): SeaEntity {
  return {
    ...entDefaults,
    id,
    x,
    y,
  }
}

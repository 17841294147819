import { addListener, emit, EventListenerList } from '../../../lib/event/event'
import ls from '../../../lib/localStorage'
import { Nullable } from '../../../typescript'
import { getSessionValue, sessionValueUpdatedEvent } from '../session/sessionValue'
import { AuthProfile } from './auth_profile.type'
import fetchAuthProfile from './fetchAuthProfile'
import getAuthProfileValueFromLocalStorage, {
  PROFILE_VALUE_LS_KEY,
} from './getAuthProfileValueFromLocalStorage'

export const profileValueUpdatedEvent: EventListenerList<Nullable<AuthProfile>> = [
  (newValue) => {
    ls.setItem(PROFILE_VALUE_LS_KEY, JSON.stringify(newValue || null))
  },
]

addListener(sessionValueUpdatedEvent, (sessionValue) => {
  if (sessionValue?.user?.id !== profileValue?.id) {
    fetchAuthProfile(sessionValue?.user?.id).then(updateAuthProfileValue)
  }
})

let profileValue: Nullable<AuthProfile> = getAuthProfileValueFromLocalStorage()

export function getProfileValue() {
  return profileValue
}

if (!profileValue) {
  fetchAuthProfile(getSessionValue()?.user?.id).then(updateAuthProfileValue)
}

export function updateAuthProfileValue(newValue: Nullable<AuthProfile>): void {
  profileValue = newValue
  emit(profileValueUpdatedEvent, newValue)
}

window.addEventListener('storage', function (event) {
  if (event.key === PROFILE_VALUE_LS_KEY) {
    const newValue: Nullable<AuthProfile> = event.newValue ? JSON.parse(event.newValue) : null
    updateAuthProfileValue(newValue)
  }
})

import { ProfileIdSchema } from '@sg/st1/src/lib/core/ProfileId.type'
import type { State } from '@sg/st1/src/lib/core/state/State.type'
import { StateType } from '@sg/st1/src/lib/core/state/state_type.enum'
import { Context } from 'hono'
import { object, optional, parse, type InferOutput } from 'valibot'
import authOr401 from '../../../lib/auth/authOr401'
import createAdminSupabaseClient from '../../../lib/supabase/createAdminSupabaseClient'

export const IndexGameQuerySchema = object({
  owner_profile_uuid: optional(ProfileIdSchema),
  player_profile_uuid: optional(ProfileIdSchema),
  // deleted: optional(nullable(picklist([0, 1]))),
  // sortBy: optional(nullable(ProfileIdSchema)),
})

export type IndexGameQuery = InferOutput<typeof IndexGameQuerySchema>

const SelectColumns = `
id,
data,
created_at,
updated_at,
published_at,
deleted_at,
creator:profiles!st1_states_creator_profile_uuid_profile_fkey(id, dname, handle, avatar_url),
owner:profiles!st1_states_owner_profile_uuid_profile_fkey(id, dname, handle, avatar_url),
turn:profiles!st1_states_turn_profile_uuid_profile_fkey(id, dname, handle, avatar_url)
`

async function dummyFetch(c: Context, newState: State) {
  const list = (await createAdminSupabaseClient(c).from('st1_states').select(SelectColumns)).data
  if (list) {
    return list.map((v) => ({
      ...v,
      data: newState,
    }))
  }
}

export type GameRowCollection = NonNullable<Awaited<ReturnType<typeof dummyFetch>>>

export default async function handleIndexGame(c: Context) {
  console.log('handleIndexGame')
  const auth = await authOr401(c)

  const input: IndexGameQuery = parse(IndexGameQuerySchema, c.req.query())

  // const supabaseUser = createUserSupabaseClient(c)
  const supabaseAdmin = createAdminSupabaseClient(c)

  const query = supabaseAdmin.from('st1_states').select(SelectColumns)
  query.eq('state_type', StateType.Game)
  if (input.player_profile_uuid) {
    query.contains('player_profile_uuid_list', [input.player_profile_uuid])
    // query.filter('player_profile_uuid_list', 'ov', `{${input.player_profile_uuid}}`)
  }

  if (input.owner_profile_uuid) {
    query.eq('owner_profile_uuid', input.owner_profile_uuid)
    query.eq('owner_profile_uuid', input.owner_profile_uuid)
  }

  // does not apply to games
  // const isSearchingOwn = auth.sub && auth.sub === input.owner_profile_uuid
  // if (!isSearchingOwn) {
  //   query.not('published_at', 'is', null)
  // }
  query.is('deleted_at', null)
  query.order('updated_at', { ascending: false })

  const stateResponse = await query

  const { error: stateRowError, data } = stateResponse

  if (stateRowError) {
    throw stateRowError
  }

  const stateRowData = data as GameRowCollection

  // for (let index = 0; index < stateRowData.length; index++) {
  //   const stateRow = stateRowData[index];
    
  //   await debugResetGame(stateRow.id, stateRow, supabaseAdmin)
  // }

  // const state = parse(StateSchema, stateRowData.state)
  // if (state.type === StateType.Game) {
  //   if (state.config.fow) {
  //     const auth = await authOr401(c)
  //     // console.log('auth', auth)
  //     const userId: string = auth.sub as string
  //     console.log('userId', userId)
  //     if (!userId) {
  //       throw new Error('User not found')
  //     }
  //     throw new Error('Not Implemented: FoW needs redaction logic')
  //   }
  // }

  return c.json(stateRowData)
}

import { DisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { InferOutput, boolean, fallback, nullable, object, optional, string } from 'valibot'
import { PlayerIdSchema } from './PlayerId'
import { PlayerKindSchema } from './PlayerKind'
import { PlayerMoneySchema } from './PlayerMoney.type'
import { PlayerTurnStatus, PlayerTurnStatusSchema } from './PlayerTurnStatus'

export const PlayerSchema = object({
  id: PlayerIdSchema,
  profile_id: optional(nullable(string())),
  invite_id: optional(nullable(string())),
  bot_id: optional(nullable(string())),
  is_local: optional(nullable(boolean())),
  kind: PlayerKindSchema,
  dname: optional(nullable(DisplayNameSchema)),
  dname_placeholder: optional(nullable(DisplayNameSchema)),
  color1: string(),
  color2: string(),
  money: fallback(PlayerMoneySchema, 0),
  turn_status: fallback(PlayerTurnStatusSchema, PlayerTurnStatus.Waiting),
})

export type Player = InferOutput<typeof PlayerSchema>

import { useSearchParams } from '@solidjs/router'
import axios from 'axios'
import { Component, createSignal } from 'solid-js'
import AlertBag from '../../components/AlertBag'
import Loader from '../../components/Loader'
import { createLoadingSignal } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, setAlertBagError, setAlertBagSuccess } from '../../rx/store/create_alert_bag_store'
import preventDefault from '../../lib/dom/event/prevent_default'

// curl 'https://hwylrqkmnilaijnprsat.supabase.co/auth/v1/otp?redirect_to=http%3A%2F%2Flocalhost%3A3000%2Fmaps' \
//   -H 'authority: hwylrqkmnilaijnprsat.supabase.co' \
//   -H 'accept: */*' \
//   -H 'accept-language: en-US,en;q=0.9' \
//   -H 'apikey: REDACTED' \
//   -H 'authorization: Bearer REDACTED' \
//   -H 'content-type: application/json;charset=UTF-8' \
//   -H 'origin: http://localhost:3000' \
//   -H 'referer: http://localhost:3000/' \
//   -H 'sec-ch-ua: "Chromium";v="113", "Not-A.Brand";v="24"' \
//   -H 'sec-ch-ua-mobile: ?0' \
//   -H 'sec-ch-ua-platform: "macOS"' \
//   -H 'sec-fetch-dest: empty' \
//   -H 'sec-fetch-mode: cors' \
//   -H 'sec-fetch-site: cross-site' \
//   -H 'user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Iron Safari/537.36' \
//   -H 'x-client-info: supabase-js-web/2.42.7' \
//   -H 'x-supabase-api-version: 2024-01-01' \
//   --data-raw '{"email":"masterexon@gmail.com","data":{},"create_user":true,"gotrue_meta_security":{},"code_challenge":null,"code_challenge_method":null}' \
//   --compressed

type SignInPageSearchParams = Record<'return_to', string>

const SignInPage: Component = () => {
  const loading = createLoadingSignal()
  const ab = createAlertBagStore()
  const [email, setEmail] = createSignal('')

  const [searchParams] = useSearchParams<SignInPageSearchParams>()

  const handleLogin = async (e: SubmitEvent) => {
    preventDefault(e)
    setAlertBagError(ab, null)
    setAlertBagSuccess(ab, null)

    try {
      loading.start()

      // axios-jwt
      // setAuthTokens({
      //   accessToken: response.data.access_token,
      //   refreshToken: response.data.refresh_token
      // })

      // POST /auth/v1/otp
      await axios.post(`${import.meta.env.VITE_SUPABASE_URL}/auth/v1/otp`, {
        email: email(),
        data: {},
        create_user: true,
        gotrue_meta_security: {},
        code_challenge: null,
        code_challenge_method: null,
      }, {
        params: {
          redirect_to: `${window.location.origin}${searchParams.return_to}`,
        },
        headers: {
          apikey: import.meta.env.VITE_SUPABASE_ANON_KEY,
        },
      })
      // response was empty object when testing.

      // const { error } = await supabase.auth.signInWithOtp({
      //   email: email(),
      //   options: {
      //     emailRedirectTo: `${window.location.origin}${searchParams.return_to}`,
      //   }
      // })
      // if (error) throw error
      setAlertBagSuccess(ab, 'Check your email for login link!')
    } catch (error) {
      console.error(error)
      setAlertBagError(ab, error as Error)
    } finally {
      loading.end()
    }
  }

  return (
    <div class="d-flex justify-content-center">
      <div class="col-6">
        <h1>Simple Tactics</h1>
        <form onSubmit={handleLogin}>
          <div class="form-group mb-3">
            <p class="form-text">Sign in via magic link with your email below</p>
            <label class="form-label" for="email">Email</label>
            <input
              id="email"
              class="form-control"
              type="email"
              placeholder="Your email"
              value={email()}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
          <div class="form-group mb-3">
            <button type="submit" class="btn btn-primary d-block">
              {loading() ? <span>Loading</span> : <span>Send magic link</span>}
            </button>
          </div>
          <AlertBag store={ab} />
        </form>
      </div>
      <Loader signal={loading} />
    </div>
  )
}

export default SignInPage

import { backendAxios } from '../../../../axios'
import noop from '../../../ldsh/noop'
import { Engine } from '../../engine/Engine.type'
import type { StateOnlineId } from '../StateOnlineId.type'
import Action from './action/Action.type'
import { typeToPathMap } from './typeToPathMap'

let prevPromise: Promise<unknown> | null = null

export default async function postRemoteAction(
  engine: Engine,
  action: Action,
  game_id: StateOnlineId
): Promise<void> {
  await prevPromise

  const nextPromise = postRemoteActionInner(engine, action, game_id).catch(noop)

  prevPromise = nextPromise.catch(noop)

  return nextPromise
}

async function postRemoteActionInner(
  engine: Engine,
  action: Action,
  game_id: StateOnlineId
): Promise<void> {
  // console.log('<postRemoteActionInner>')
  const typePath = typeToPathMap[engine.state.type]
  const path = `/st1/${typePath}/${game_id}`
  const body = { action }
  // console.log('postRemoteAction', path, body)

  /*const response = */ await backendAxios.post(path, body)

  // if (response.data.state) {
  //   engine.state = response.data.state
  // } else {
  //   const r2 = await backendAxios.get(path)
  //   if (r2.data.state) {
  //     engine.state = r2.data.state
  //   }
  // }
  // console.log('</postRemoteActionInner>')
}

import type { RNGConfig } from '../rng/RNGConfig.type'
import type { State } from '../state/State.type'
import { randomId } from '../util/rand'
import nextMapEditorPlayer from './nextMapEditorPlayer'
import type { Player } from './Player.type'
import { PlayerKind } from './PlayerKind'
import { PlayerTurnStatus } from './PlayerTurnStatus'

export default function createMapEditorPlayer(state: State, rng: RNGConfig): Player {
  const id: number = randomId(state.players, rng)
  const player: Player = {
    id,
    kind: PlayerKind.OpenSlot,
    money: 0,
    ...nextMapEditorPlayer(state),
    turn_status: PlayerTurnStatus.Waiting,
  }
  return player
}

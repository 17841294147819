/**
 * This file is automatically generated.
 **/
import { ActionType } from './action/ActionType'
import ActionHandler from './ActionHandler.type'

import { handleCancelFactoryOrderAction, CancelFactoryOrderActionSchema } from './action/Game/CancelFactoryOrderAction'
import { handleEndRoundAction, EndRoundActionSchema } from './action/Game/EndRoundAction'
import { handleEndTurnAction, EndTurnActionSchema } from './action/Game/EndTurnAction'
import { handleMoveUnitAction, MoveUnitActionSchema } from './action/Game/MoveUnitAction'
import { handlePlaceFactoryOrderAction, PlaceFactoryOrderActionSchema } from './action/Game/PlaceFactoryOrderAction'
import { handleStartRoundAction, StartRoundActionSchema } from './action/Game/StartRoundAction'
import { handleStartTurnAction, StartTurnActionSchema } from './action/Game/StartTurnAction'
import { handleClaimPlayerSlotAction, ClaimPlayerSlotActionSchema } from './action/Lobby/ClaimPlayerSlotAction'
import { handleKickPlayerSlotAction, KickPlayerSlotActionSchema } from './action/Lobby/KickPlayerSlotAction'
import { handleLeavePlayerSlotAction, LeavePlayerSlotActionSchema } from './action/Lobby/LeavePlayerSlotAction'
import { handlePatchConfigAction, PatchConfigActionSchema } from './action/Lobby/PatchConfigAction'
import { handleReservePlayerSlotAction, ReservePlayerSlotActionSchema } from './action/Lobby/ReservePlayerSlotAction'
import { handleSetBotPlayerSlotAction, SetBotPlayerSlotActionSchema } from './action/Lobby/SetBotPlayerSlotAction'
import { handleStartNewGameAction, StartNewGameActionSchema } from './action/Lobby/StartNewGameAction'
import { handleAddPlayerAction, AddPlayerActionSchema } from './action/Map/AddPlayerAction'
import { handleDeletePlayerAction, DeletePlayerActionSchema } from './action/Map/DeletePlayerAction'
import { handleEraseTileAction, EraseTileActionSchema } from './action/Map/EraseTileAction'
import { handleMapToLobbyAction, MapToLobbyActionSchema } from './action/Map/MapToLobbyAction'
import { handlePaintTileAction, PaintTileActionSchema } from './action/Map/PaintTileAction'
import { handlePatchMapAction, PatchMapActionSchema } from './action/Map/PatchMapAction'
import { handlePatchPlayerAction, PatchPlayerActionSchema } from './action/Map/PatchPlayerAction'
import { handleSetStateAction, SetStateActionSchema } from './action/Map/SetStateAction'

export type ActionSchemaUnion = typeof CancelFactoryOrderActionSchema | typeof EndRoundActionSchema | typeof EndTurnActionSchema | typeof MoveUnitActionSchema | typeof PlaceFactoryOrderActionSchema | typeof StartRoundActionSchema | typeof StartTurnActionSchema | typeof ClaimPlayerSlotActionSchema | typeof KickPlayerSlotActionSchema | typeof LeavePlayerSlotActionSchema | typeof PatchConfigActionSchema | typeof ReservePlayerSlotActionSchema | typeof SetBotPlayerSlotActionSchema | typeof StartNewGameActionSchema | typeof AddPlayerActionSchema | typeof DeletePlayerActionSchema | typeof EraseTileActionSchema | typeof MapToLobbyActionSchema | typeof PaintTileActionSchema | typeof PatchMapActionSchema | typeof PatchPlayerActionSchema | typeof SetStateActionSchema

type ActionHandlerTuple = [ActionType, ActionSchemaUnion, ActionHandler];

// const actionHandlersSet = new Map<ActionType, ActionHandler>()

// actionHandlersSet.set(ActionType.Game.CancelFactoryOrder, handleCancelFactoryOrderAction as ActionHandler)
// actionHandlersSet.set(ActionType.Game.EndRound, handleEndRoundAction as ActionHandler)
// actionHandlersSet.set(ActionType.Game.EndTurn, handleEndTurnAction as ActionHandler)
// actionHandlersSet.set(ActionType.Game.MoveUnit, handleMoveUnitAction as ActionHandler)
// actionHandlersSet.set(ActionType.Game.PlaceFactoryOrder, handlePlaceFactoryOrderAction as ActionHandler)
// actionHandlersSet.set(ActionType.Game.StartRound, handleStartRoundAction as ActionHandler)
// actionHandlersSet.set(ActionType.Game.StartTurn, handleStartTurnAction as ActionHandler)
// actionHandlersSet.set(ActionType.Lobby.ClaimPlayerSlot, handleClaimPlayerSlotAction as ActionHandler)
// actionHandlersSet.set(ActionType.Lobby.KickPlayerSlot, handleKickPlayerSlotAction as ActionHandler)
// actionHandlersSet.set(ActionType.Lobby.LeavePlayerSlot, handleLeavePlayerSlotAction as ActionHandler)
// actionHandlersSet.set(ActionType.Lobby.PatchConfig, handlePatchConfigAction as ActionHandler)
// actionHandlersSet.set(ActionType.Lobby.ReservePlayerSlot, handleReservePlayerSlotAction as ActionHandler)
// actionHandlersSet.set(ActionType.Lobby.SetBotPlayerSlot, handleSetBotPlayerSlotAction as ActionHandler)
// actionHandlersSet.set(ActionType.Lobby.StartNewGame, handleStartNewGameAction as ActionHandler)
// actionHandlersSet.set(ActionType.Map.AddPlayer, handleAddPlayerAction as ActionHandler)
// actionHandlersSet.set(ActionType.Map.DeletePlayer, handleDeletePlayerAction as ActionHandler)
// actionHandlersSet.set(ActionType.Map.EraseTile, handleEraseTileAction as ActionHandler)
// actionHandlersSet.set(ActionType.Map.MapToLobby, handleMapToLobbyAction as ActionHandler)
// actionHandlersSet.set(ActionType.Map.PaintTile, handlePaintTileAction as ActionHandler)
// actionHandlersSet.set(ActionType.Map.PatchMap, handlePatchMapAction as ActionHandler)
// actionHandlersSet.set(ActionType.Map.PatchPlayer, handlePatchPlayerAction as ActionHandler)
// actionHandlersSet.set(ActionType.Map.SetState, handleSetStateAction as ActionHandler)

const actionHandlersList : Array<ActionHandlerTuple> = [
  [ActionType.Game.CancelFactoryOrder,
    CancelFactoryOrderActionSchema,
    handleCancelFactoryOrderAction as ActionHandler],
  [ActionType.Game.EndRound,
    EndRoundActionSchema,
    handleEndRoundAction as ActionHandler],
  [ActionType.Game.EndTurn,
    EndTurnActionSchema,
    handleEndTurnAction as ActionHandler],
  [ActionType.Game.MoveUnit,
    MoveUnitActionSchema,
    handleMoveUnitAction as ActionHandler],
  [ActionType.Game.PlaceFactoryOrder,
    PlaceFactoryOrderActionSchema,
    handlePlaceFactoryOrderAction as ActionHandler],
  [ActionType.Game.StartRound,
    StartRoundActionSchema,
    handleStartRoundAction as ActionHandler],
  [ActionType.Game.StartTurn,
    StartTurnActionSchema,
    handleStartTurnAction as ActionHandler],
  [ActionType.Lobby.ClaimPlayerSlot,
    ClaimPlayerSlotActionSchema,
    handleClaimPlayerSlotAction as ActionHandler],
  [ActionType.Lobby.KickPlayerSlot,
    KickPlayerSlotActionSchema,
    handleKickPlayerSlotAction as ActionHandler],
  [ActionType.Lobby.LeavePlayerSlot,
    LeavePlayerSlotActionSchema,
    handleLeavePlayerSlotAction as ActionHandler],
  [ActionType.Lobby.PatchConfig,
    PatchConfigActionSchema,
    handlePatchConfigAction as ActionHandler],
  [ActionType.Lobby.ReservePlayerSlot,
    ReservePlayerSlotActionSchema,
    handleReservePlayerSlotAction as ActionHandler],
  [ActionType.Lobby.SetBotPlayerSlot,
    SetBotPlayerSlotActionSchema,
    handleSetBotPlayerSlotAction as ActionHandler],
  [ActionType.Lobby.StartNewGame,
    StartNewGameActionSchema,
    handleStartNewGameAction as ActionHandler],
  [ActionType.Map.AddPlayer,
    AddPlayerActionSchema,
    handleAddPlayerAction as ActionHandler],
  [ActionType.Map.DeletePlayer,
    DeletePlayerActionSchema,
    handleDeletePlayerAction as ActionHandler],
  [ActionType.Map.EraseTile,
    EraseTileActionSchema,
    handleEraseTileAction as ActionHandler],
  [ActionType.Map.MapToLobby,
    MapToLobbyActionSchema,
    handleMapToLobbyAction as ActionHandler],
  [ActionType.Map.PaintTile,
    PaintTileActionSchema,
    handlePaintTileAction as ActionHandler],
  [ActionType.Map.PatchMap,
    PatchMapActionSchema,
    handlePatchMapAction as ActionHandler],
  [ActionType.Map.PatchPlayer,
    PatchPlayerActionSchema,
    handlePatchPlayerAction as ActionHandler],
  [ActionType.Map.SetState,
    SetStateActionSchema,
    handleSetStateAction as ActionHandler],
]


export default actionHandlersList
import { literal, object, optional, pipe, rawCheck, type InferOutput } from 'valibot'
import type { Nullable } from '../../../../../../typescript'
import NotYourTurn from '../../../../../Exception/NotYourTurn.class'
import Unexpected from '../../../../../Exception/Unexpected.class'
import isNil from '../../../../../ldsh/isNil'
import type { HasAttackedThisTurn } from '../../../../AttackedThisTurn'
import createDraftMoveAttackEstimate from '../../../../draft_move/createDraftMoveAttackEstimate'
import type { DraftMove } from '../../../../draft_move/DraftMove.type'
import { Engine } from '../../../../engine/Engine.type'
import resetEngineToDefaultSelectedTool from '../../../../engine/resetEngineToDefaultSelectedTool'
import type { BaseEntity } from '../../../../entity/BaseEntity'
import canUnitAnnexEntity from '../../../../entity/canUnitAnnexEntity'
import entityTypeMetaList from '../../../../entity/entityTypeMetaList.generated'
import findEntityById from '../../../../entity/findEntityById'
import getEntitiesAtPosition from '../../../../entity/getEntitiesAtPosition'
import getUnitMoveCostOntoPositionEntityStack from '../../../../entity/getUnitMoveCostOntoPositionEntityStack'
import type { HasTaxiID } from '../../../../entity/HasTaxiID'
import { EntityIdSchema, NullableEntityIdSchema, type EntityId } from '../../../../entity/id.type'
import type { Entity } from '../../../../entity/index'
import findByIdOrThrow from '../../../../findByIdOrThrow'
import type { HasFuel } from '../../../../has_fuel'
import type { HasHP } from '../../../../has_hp'
import type { HasMobility } from '../../../../has_mobility'
import type { HasAnnexPoints } from '../../../../HasAnnexPoints.type'
import type { HasPrice } from '../../../../HasPrice'
import { NullableLiteralTrueSchema, OptionalLiteralTrueSchema } from '../../../../LiteralTrue'
import type { HasPlayerId } from '../../../../player/has_player_id'
import { PlayerTurnStatus } from '../../../../player/PlayerTurnStatus'
import entityListToGridXY from '../../../../tile_position_xy/entityListToGridXY'
import findMostEfficientPathToTileV2 from '../../../../tile_position_xy/findMostEfficientPathToTileV2/findMostEfficientPathToTileV2'
import isOrthogonallyAdjacent from '../../../../tile_position_xy/isOrthogonallyAdjacent'
import { samePosition } from '../../../../tile_position_xy/samePosition'
import { NullableTilePositionXYSchema, TilePositionXYSchema } from '../../../../tile_position_xy/TilePositionXY.type'
import toCoord from '../../../../tile_position_xy/toCoord'
import { max, min } from '../../../../util/math'
import type { HasWaitedThisTurn } from '../../../../WaitedThisTurn'
import { ActionType } from '../ActionType'

export const MoveUnitActionSchema = pipe(object({
  type: literal(ActionType.Game.MoveUnit),
  unit_id: EntityIdSchema,
  target_id: NullableEntityIdSchema,
  pickup_id: optional(NullableEntityIdSchema),
  start_position: TilePositionXYSchema,
  dest_position: NullableTilePositionXYSchema,
  attack_position: NullableTilePositionXYSchema,
  annex: NullableLiteralTrueSchema,
  wait: OptionalLiteralTrueSchema,
  taxi_id: optional(NullableEntityIdSchema),
  cargo_id: optional(NullableEntityIdSchema),
  unload_position: optional(NullableTilePositionXYSchema),
  merge_id: optional(NullableEntityIdSchema),
}), rawCheck(({ dataset, addIssue }) => {
  if (dataset.typed) {
    // console.log('dataset.value', dataset.value)
    const {
      target_id,
      dest_position,
      attack_position,
      annex,
      wait,
      pickup_id,
      taxi_id,
      unload_position,
      cargo_id,
      merge_id,
    } = dataset.value
    if (!(target_id||dest_position||attack_position||annex||wait||pickup_id||unload_position)) {
      addIssue({
        message: 'Blank Move is Invalid',
      })
    }
    if (attack_position && !target_id) {
      addIssue({
        message: 'attack_position requires target_id',
      })
    }
    if (pickup_id) {
      if (attack_position) {
        addIssue({
          message: 'cannot combine pickup and attack',
        })
      }
      if (annex) {
        addIssue({
          message: 'cannot combine pickup and annex',
        })
      }
      if (unload_position) {
        addIssue({
          message: 'cannot combine pickup and unload_position',
        })
      }
    }
    if (taxi_id) {
      if (attack_position) {
        addIssue({
          message: 'cannot combine taxi and attack',
        })
      }
      if (annex) {
        addIssue({
          message: 'cannot combine taxi and annex',
        })
      }
      if (pickup_id) {
        addIssue({
          message: 'cannot combine taxi and pickup',
        })
      }
    }
    if (unload_position) {
      if (!cargo_id) {
        addIssue({
          message: 'unload_position requires cargo_id',
        })
      }
      if (attack_position) {
        addIssue({
          message: 'cannot combine unload and attack',
        })
      }
      if (annex) {
        addIssue({
          message: 'cannot combine unload and annex',
        })
      }
    }
    if (merge_id) {
      if (!dest_position) {
        addIssue({
          message: 'merge requires dest_position',
        })
      }
      if (attack_position) {
        addIssue({
          message: 'cannot combine merge and attack',
        })
      }
      if (annex) {
        addIssue({
          message: 'cannot combine merge and annex',
        })
      }
    }
  }
}))

export type MoveUnitAction = InferOutput<typeof MoveUnitActionSchema>

export function createMoveUnitAction(draftMove: DraftMove): MoveUnitAction {
  return {
    type: ActionType.Game.MoveUnit,
    unit_id: draftMove.unit.id,
    target_id: draftMove.target?.id || null,
    pickup_id: draftMove.pickup?.id,
    taxi_id: draftMove.taxi?.id,
    start_position: draftMove.startPosition,
    dest_position: draftMove.destPosition,
    attack_position: draftMove.attackPosition,
    annex: draftMove.annex,
    wait: draftMove.wait,
    cargo_id: draftMove.unload?.cargo_id,
    unload_position: draftMove.unloadPosition,
    merge_id: draftMove.merge?.id,
  }
}

export async function handleMoveUnitAction(engine: Engine, action: MoveUnitAction): Promise<void> {
  // console.log('handleMoveUnitAction', deepClone(action))
  const {
    annex,
    unit_id,
    target_id,
    pickup_id,
    start_position,
    dest_position,
    attack_position,
    wait,
    taxi_id,
    cargo_id,
    unload_position,
    merge_id,
  } = action
  const { state } = engine
  const { ents, width, height } = state
  const unit = findEntityById(ents, unit_id)
  if (!unit) {
    throw new Unexpected('!unit')
  }
  if (!samePosition(unit, start_position)) {
    throw new Unexpected('!samePosition(unit, start_position)')
  }
  const unitEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, unit.etype_id)
  const {
    mobility: unitDefaultMobility,
    price: unitDefaultPrice,
    fuel: unitDefaultFuel,
  } = unitEntityTypeMeta.entDefaults as HasMobility & HasPrice & HasFuel
  const entityGridXY = entityListToGridXY(ents, width, height)
  const unitPosition = dest_position || toCoord(unit)

  const unitPlayer = findByIdOrThrow(state.players, (unit as HasPlayerId).player_id)
  if (unitPlayer.turn_status !== PlayerTurnStatus.Playing) {
    throw new NotYourTurn
  }

  if (annex && attack_position) {
    throw new Unexpected('annex && attack_position')
  }

  if (dest_position) {
    // console.log('the unit is going somewhere')
    if (samePosition(unit, dest_position)) {
      throw new Unexpected('samePosition(unit, dest_position)')
    }
    if (!('mobility' in unit)) {
      throw new Error('Unit cant move')
    }
    const unitPathTurnSteps = findMostEfficientPathToTileV2(
      entityGridXY,
      unit,
      width,
      height,
      dest_position.x,
      dest_position.y
    )
    if (!unitPathTurnSteps) {
      throw new Error('No Valid Path')
    }
    // console.log('unitPathTurnSteps', unitPathTurnSteps)
    const turn = unitPathTurnSteps.turns[0]
    if (!turn) {
      throw new Error('No Valid Path')
    }
    const stepsMobilityCost = turn.steps.reduce((sum: number, step) => sum + (step.cost || 0), 0)
    if (!(unit.mobility > 0)) {
      throw new Error('Unit out of movies')
    }
    const lastStep = turn.steps[turn.steps.length - 1]
    if (!lastStep) {
      throw new Error('No Valid Path')
    }

    if (lastStep.impass) {
      throw new Unexpected('dest_position: impass')
    }

    unit.x = lastStep.x
    unit.y = lastStep.y

    unit.mobility = max(0, unit.mobility - stepsMobilityCost)
    unit.fuel = max(0, unit.fuel - stepsMobilityCost)

    const { cargo } = (unit as BaseEntity)
    if (cargo) {
      for (let index = 0; index < ents.length; index++) {
        const ent2 = ents[index];
        if ((ent2 as HasTaxiID).taxi_id === unit.id) {
          ent2.x = unit.x
          ent2.y = unit.y
        }
      }
    }
  }

  if (annex) {
    const annexPosition = dest_position || start_position
    // console.log('the unit is annexing something')

    if (annexPosition) {
      const annexableEnt = engine.state.ents.find(
        (entity: Entity): entity is Entity & HasAnnexPoints => {
          if (samePosition(entity, annexPosition)) {
            return canUnitAnnexEntity(unit, entity)
          }
          return false
        }
      )
      if (!annexableEnt) {
        throw new Unexpected('!annexableEnt')
      }
      const annexDelta = (unit as HasHP).hp
      if (!annexDelta) {
        throw new Unexpected('!annexDelta')
      }
      const annexableEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, annexableEnt.etype_id)
      const defaultAnnexPoints = (annexableEntityTypeMeta.entDefaults as HasAnnexPoints).ap

      // TODO also cleanup orphaned annexations
      if (annexableEnt.ap_ent_id !== unit.id) {
        annexableEnt.ap = defaultAnnexPoints
        annexableEnt.ap_ent_id = unit.id
      }
      annexableEnt.ap -= annexDelta
      if (annexableEnt.ap <= 0) {
        ;(annexableEnt as HasPlayerId).player_id = (unit as HasPlayerId).player_id
        annexableEnt.ap = defaultAnnexPoints
        annexableEnt.ap_ent_id = null
      }
      ;(unit as HasMobility).mobility = 0
    }
  }

  if (attack_position) {
    if (samePosition(unit, attack_position)) {
      throw new Unexpected('samePosition(unit, attack_position)')
    }
    if (!target_id) {
      throw new Unexpected('!target_id')
    }
    const target = findEntityById(ents, target_id)
    if (!target) {
      throw new Unexpected('!target')
    }
    if ((target as HasTaxiID).taxi_id) {
      throw new Unexpected('target.taxi_id')
    }
    if (!samePosition(target, attack_position)) {
      throw new Unexpected('!samePosition(target, attack_position)')
    }

    const isRangedAttack = !isOrthogonallyAdjacent(attack_position, dest_position || start_position)

    if (isRangedAttack) {
      const unitHasFullMobilityPoints = unitDefaultMobility && unitDefaultMobility === (unit as HasMobility).mobility
      if (!unitHasFullMobilityPoints) {
        throw new Unexpected('unitDefaultMobility!==unit.mobility')
      }
    }

    const weaponEstimate = createDraftMoveAttackEstimate(engine, unit, unitPosition, target)
    // if (!weaponEstimate) {
    //   throw new Unexpected('!weaponEstimate')
    // }
    const { unitWeaponEstimate, targetWeaponEstimate } = weaponEstimate
    if (!unitWeaponEstimate) {
      throw new Unexpected('!unitWeaponEstimate')
    }
    const { weapon: unitWeapon } = unitWeaponEstimate
    // the initial attack
    if (!isNil(weaponEstimate.targetDmg)) {
      if (unitWeapon.ammo) {
        unitWeapon.ammo--
      }
      ;(target as HasHP).hp -= weaponEstimate.targetDmg
    } else {
      throw new Unexpected('!(unitWeapon && !isNil(weaponEstimate.targetDmg))')
    }

    // the counter attack
    const targetWeapon = targetWeaponEstimate?.weapon 
    if (targetWeapon && !isNil(weaponEstimate.unitDmg) && (target as HasHP).hp >= 0) {
      if (targetWeapon.ammo) {
        targetWeapon.ammo--
      }
      ;(unit as HasHP).hp -= weaponEstimate.unitDmg
    }

    if (!((target as HasHP).hp >= 0)) {
      state.ents = ents.filter(ent => {
        // the unit is dead
        if (ent.id == target_id) {
          return false
        }
        // the unit's cargo is destroyed
        if ((ent as HasTaxiID).taxi_id == target_id) {
          return false
        }
        return true
      })
    }

    ;(unit as HasMobility).mobility = 0
    ;(unit as HasAttackedThisTurn).attackedThisTurn = true
  }

  if (pickup_id) {
    const cargoEnt = findEntityById(ents, pickup_id)
    if (!cargoEnt) {
      throw new Unexpected('!cargoEnt')
    }
    if ((cargoEnt as HasTaxiID).taxi_id) {
      throw new Unexpected('cargoEnt already in taxi')
    }
    const { transports } = unitEntityTypeMeta
    if (!(transports && transports.includes(cargoEnt.etype_id))) {
      throw new Unexpected('!unit.canTransport(cargoEnt)')
    }
    const { cargo } = (unit as BaseEntity)
    if (!cargo) {
      throw new Unexpected('!cargo')
    }
    if (!(cargo.length < (unitEntityTypeMeta.cargoLimit || 0))) {
      throw new Error('transport full')
    }
    cargo.push(cargoEnt.id)
    ;(cargoEnt as HasTaxiID).taxi_id = unit.id
  }

  if (taxi_id) {
    const taxiEnt = findEntityById(ents, taxi_id)
    if (!taxiEnt) {
      throw new Unexpected('!taxiEnt')
    }
    if ((unit as HasTaxiID).taxi_id) {
      throw new Unexpected('unit already in taxi')
    }
    const taxiEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, taxiEnt.etype_id)
    const { transports } = taxiEntityTypeMeta
    if (!(transports && transports.includes(unit.etype_id))) {
      throw new Unexpected('!taxiEnt.canTransport(unit)')
    }
    const { cargo } = (taxiEnt as BaseEntity)
    if (!cargo) {
      throw new Unexpected('!cargo')
    }
    if (!(cargo.length < (taxiEntityTypeMeta.cargoLimit || 0))) {
      throw new Error('transport full')
    }
    cargo.push(unit.id)
    ;(unit as HasTaxiID).taxi_id = taxiEnt.id
  }

  if (unload_position && cargo_id) {
    const cargoEnt = findEntityById(ents, cargo_id)
    if (!cargoEnt) {
      throw new Unexpected('!cargoEnt')
    }
    if ((cargoEnt as HasTaxiID).taxi_id !== unit.id) {
      throw new Unexpected('cargoEnt.taxi_id !== unit.id')
    }
    const { cargo } = (unit as BaseEntity)
    if (!cargo) {
      throw new Unexpected('!cargo')
    }
    if (!cargo.includes(cargoEnt.id)) {
      throw new Unexpected('!unit.cargo.has(cargoEnt)')
    }
    const entsAtUnloadPosition = getEntitiesAtPosition(ents, unload_position)
    const mc = getUnitMoveCostOntoPositionEntityStack(cargoEnt, entsAtUnloadPosition)
    if (isNil(mc)) {
      throw new Error('nontraversible')
    }
    ;(unit as BaseEntity).cargo = [...cargo.filter((id) => id !== cargo_id)]
    delete (cargoEnt as { taxi_id: Nullable<EntityId> }).taxi_id
    cargoEnt.x = unload_position.x
    cargoEnt.y = unload_position.y
    if ((cargoEnt as HasMobility).mobility >= 1) {
      ;(cargoEnt as HasMobility).mobility = 0
    }
  }

  if (dest_position && merge_id) {
    const mergeEnt = findEntityById(ents, merge_id)
    if (!mergeEnt) {
      throw new Unexpected('!mergeEnt')
    }
    if ((mergeEnt as HasTaxiID).taxi_id) {
      throw new Unexpected('mergeEnt.taxi_id')
    }
    if (mergeEnt.etype_id !== unit.etype_id) {
      throw new Unexpected('merge entity type mismatch')
    }
    const { cargo: unitCargo } = (unit as BaseEntity)
    if (unitCargo?.length as number > 0) {
      throw new Unexpected('merge unitCargo.length')
    }
    const { cargo: mergeEntCargo } = (unit as BaseEntity)
    if (mergeEntCargo?.length as number > 0) {
      throw new Unexpected('merge mergeEntCargo.length')
    }
    const entsAtDestPosition = getEntitiesAtPosition(ents, dest_position)
    const mc = getUnitMoveCostOntoPositionEntityStack(mergeEnt, entsAtDestPosition)
    if (isNil(mc)) {
      throw new Error('nontraversible')
    }
    if ((mergeEnt as HasMobility).mobility >= 1) {
      ;(mergeEnt as HasMobility).mobility = 0
    }

    const totalHp = (unit as HasHP).hp + (mergeEnt as HasHP).hp
    const newHp = min(10, totalHp)
    const surplusHp = totalHp - newHp
    if (surplusHp >= 0 && unitDefaultPrice) {
      unitPlayer.money += (surplusHp * unitDefaultPrice) / 10
    }
    ;(mergeEnt as HasHP).hp = newHp

    if ('fuel' in mergeEnt) {
      mergeEnt.fuel = min(unitDefaultFuel, mergeEnt.fuel + (unit as HasFuel).fuel)
    }

    ;(mergeEnt as BaseEntity).weapons?.forEach?.(w1 => {
      if ('ammo' in w1) {
        ;(unit as BaseEntity).weapons?.find(w2 => {
          if (w1.wt_id === w2.wt_id && w2.ammo as number > 0) {
            ;(w1.ammo as number) += (w2.ammo as number)
            w2.ammo = 0
          }
        })
      }
    })

    // remove unit after merging
    engine.state.ents = ents.filter(ent => ent.id !== unit_id)
  }

  if (wait) {
    ;(unit as HasWaitedThisTurn).waitedThisTurn = true
  }

  resetEngineToDefaultSelectedTool(engine)
}

import { createRoot } from 'solid-js'
import { createAlertBagStore } from '../../../rx/store/create_alert_bag_store'
import { deepClone } from '../../deep_clone'
import { createEraseTileOption } from '../map_editor/erase_map_tile'
import { defaultPaintTileOption } from '../map_editor/paint_tile_option_list'
import { SelectedToolId } from '../map_editor/SelectedToolId.enum'
import { initViewCtx } from '../view_ctx'
import blankStateForEngineInit from './blankStateForEngineInit'
import { Engine } from './Engine.type'

export default function createEngineRoot(): Engine {
  return createRoot(() => {
    const defaultState = blankStateForEngineInit()
    const engine: Engine = {
      ab: createAlertBagStore(),
      authPlayerId: null,
      frameCache: [],
      lastMouseMoveEvent: null,
      lastTouchMoveEvent: null,
      canvasList: [],
      eraseToolOption: createEraseTileOption(),
      hoveredPosition: null,
      mouseDownEvent: null,
      mouseDownPosition: null,
      mouseDragList: null,
      mouseDragListAgg: null,
      mouseOutEvent: null,
      mouseUpEvent: null,
      paintToolOption: defaultPaintTileOption(),
      renderFns: [],
      selectedPlayerId: null,
      selectedPosition: null,
      selectedPositionEnts: [],
      draftMove: null,
      selectedTool: SelectedToolId.Inspect,
      state: defaultState,
      prevState: deepClone(defaultState),
      viewCtx: initViewCtx(),
      toast: null,
      touchStartEvent: null,
      touchStartPosition: null,
      touchEndEvent: null,
      touchEndPosition: null,
      touchDragList: null,
      touchDragListAgg: null,
    }

    return engine
  })
}

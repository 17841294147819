import { backendAxios } from '../../../axios'
import { Nullable } from '../../../typescript'
import type { AuthProfile } from './auth_profile.type'

type UserId = Nullable<string>

export default async function fetchAuthProfile(user_id: UserId) {
  if (user_id) {
    const response = await backendAxios.get(`/profile/${user_id}`)

    return response.data as AuthProfile
  }
}

import { StateType } from './state_type.enum'
import type { StateTypeMeta } from './StateTypeMeta.type'

const stateTypeMetaList: Array<StateTypeMeta> = [
  { id: StateType.Map, dname: 'Map' },
  { id: StateType.Lobby, dname: 'Lobby' },
  { id: StateType.Game, dname: 'Game' },
  { id: StateType.Replay, dname: 'Replay' },
] as const

export default stateTypeMetaList

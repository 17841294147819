import type { HasAnnexPoints } from '../HasAnnexPoints.type'
import type { HasPlayerId } from '../player/has_player_id'
import { EntityTypeId } from './entity_type_id.enum'
import type { Entity } from './index'

export default function canUnitAnnexEntity(
  unit: Entity,
  entity: Entity
): entity is Entity & HasAnnexPoints {
  if (unit.etype_id === EntityTypeId.Infantry) {
    if (unit.player_id && unit.player_id !== (entity as HasPlayerId).player_id) {
      if ('ap' in entity) {
        return true
      }
    }
  }
  return false
}

import noop from '../../ldsh/noop'
import type { BaseEntity } from '../entity/BaseEntity'
import type { Entity } from '../entity/index'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'
import { samePosition } from './samePosition'

type OccupantValueOrNull = Entity | null

// an occupant is a unit that occupies a position {x, y}
// at the same layer as the current unit
export default function createLazyOccupantValueGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to move
  unit: Entity
) {
  const lazyGrid = createLazyReadOnlyGridXY<OccupantValueOrNull>(
    gridWidth,
    gridHeight,
    function occupantValueGridGetter(x: number, y: number): OccupantValueOrNull {
      // eslint-disable-next-line
      const log = samePosition({x:3, y:2}, {x, y}) ? (value: unknown): void => {
        // console.log('occupantValueGridGetter', deepClone(value))
      } : noop
      const positionStack = grid?.[x]?.[y]
      log({positionStack})
      if (positionStack) {
        for (let index = 0; index < positionStack.length; index++) {
          const ent = positionStack[index]
          if ((ent as BaseEntity).taxi_id) {
            log({hasTaxiID: ent})
            continue
          } else if (ent.layer_id === unit.layer_id) {
            log({sameLayerId: ent})
            return ent
          }
          log({notMatch: ent})
        }
      }
      return null
    }
  )
  return lazyGrid
}

import { literal, nullable, object, optional, type InferOutput } from 'valibot'
import { Nullable } from '../../../../../../typescript'
import { Engine } from '../../../../engine/Engine.type'
import findPlayerByProfileId from '../../../../player/findPlayerByProfileId'
import { Player } from '../../../../player/Player.type'
import { PlayerIdSchema, type PlayerId } from '../../../../player/PlayerId'
import { PlayerKind } from '../../../../player/PlayerKind'
import resetPlayerSlotToOpen from '../../../../player/resetPlayerSlotToOpen'
import { ProfileIdSchema } from '../../../../ProfileId.type'
import { ActionType } from '../ActionType'

export const ClaimPlayerSlotActionSchema = object({
  type: literal(ActionType.Lobby.ClaimPlayerSlot),
  // if profile_id is null, then it's a local player
  profile_id: optional(nullable(ProfileIdSchema)),
  player_id: PlayerIdSchema,
})

export type ClaimPlayerSlotAction = InferOutput<typeof ClaimPlayerSlotActionSchema>

export function createClaimPlayerSlotAction(
  profile_id: ClaimPlayerSlotAction['profile_id'],
  player_id: PlayerId
): ClaimPlayerSlotAction {
  return {
    type: ActionType.Lobby.ClaimPlayerSlot,
    profile_id,
    player_id,
  }
}

export async function handleClaimPlayerSlotAction(
  engine: Engine,
  action: ClaimPlayerSlotAction
): Promise<void> {
  // console.log('handleClaimPlayerSlotAction')
  const { profile_id, player_id } = action
  const is_local = !profile_id || !engine.state.online

  const prevPlayerId: Nullable<PlayerId> = findPlayerByProfileId(engine.state, profile_id)?.id

  engine.state.players.forEach((player: Player) => {
    if (player.id === player_id) {
      // update the selected player
      resetPlayerSlotToOpen(player)
      player.kind = PlayerKind.Human
      player.is_local = is_local
      player.profile_id = profile_id
    } else if ((profile_id && player.profile_id === profile_id) || prevPlayerId === player.id) {
      // if the player was in a previous slot,
      // convert it to an open slot
      // update the selected player
      resetPlayerSlotToOpen(player)
    }
    return player
  })
}

import { isoNow } from '@sg/shared/src/lib/Date'
import { A } from '@solidjs/router'
import { FormGroup, FormLabel } from 'solid-bootstrap'
import { FaSolidFloppyDisk } from 'solid-icons/fa'
import { Component, createEffect, createSignal } from 'solid-js'
import { supabaseAxios } from '../axios'
import Breadcrumbs from '../components/Breadcrumb/Breadcrumbs'
import EditAvatar from '../components/EditAvatar'
import Loader from '../components/Loader'
import preventDefault from '../lib/dom/event/prevent_default'
import { POSTGREST_PREFER_HEADER } from '../lib/postgrest/headers'
import { AuthProfile } from '../rx/shared/profile/auth_profile.type'
import { updateAuthProfileValue } from '../rx/shared/profile/authProfileValue'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import { UserProfile } from '../rx/shared/profile/user_profile.type'
import { createSessionSignal } from '../rx/shared/session/createSessionSignal'
import { createLoadingSignal } from '../rx/signal/create_loading_signal'

const ProfilePage: Component = () => {
  const session = createSessionSignal()[0]
  const loading = createLoadingSignal()
  const [handle, setHandle] = createSignal<UserProfile['handle']>(null)
  const [dname, setDname] = createSignal<UserProfile['dname']>(null)
  const [avatarUrl, setAvatarUrl] = createSignal<UserProfile['avatar_url']>(null)

  const [authProfile] = createAuthProfileSignal()

  createEffect(() => {
    const p = authProfile()
    setAvatarUrl(p?.avatar_url || null)
    setHandle(p?.handle || null)
    setDname(p?.dname || null)
  })

  const updateProfile = async (e: Event) => {
    preventDefault(e)

    try {
      loading.start()
      const user = session()?.user
      if (user) {
        // curl 'https://hwylrqkmnilaijnprsat.supabase.co/rest/v1/profiles' \
        //   -H 'authority: hwylrqkmnilaijnprsat.supabase.co' \
        //   -H 'accept: */*' \
        //   -H 'accept-language: en-US,en;q=0.9' \
        //   -H 'apikey: REDACTED' \
        //   -H 'authorization: Bearer REDACTED' \
        //   -H 'content-profile: public' \
        //   -H 'content-type: application/json' \
        //   -H 'origin: http://localhost:3000' \
        //   -H 'prefer: resolution=merge-duplicates' \
        //   -H 'referer: http://localhost:3000/' \
        //   -H 'sec-ch-ua: "Chromium";v="113", "Not-A.Brand";v="24"' \
        //   -H 'sec-ch-ua-mobile: ?0' \
        //   -H 'sec-ch-ua-platform: "macOS"' \
        //   -H 'sec-fetch-dest: empty' \
        //   -H 'sec-fetch-mode: cors' \
        //   -H 'sec-fetch-site: cross-site' \
        //   -H 'user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Iron Safari/537.36' \
        //   -H 'x-client-info: supabase-js-web/2.42.7' \
        //   --data-raw '{"id":"e07cada7-1a5c-4aaa-9541-96c32a712f8c","handle":"a_handle2","dname":"MyDisplayName","avatar_url":"0.9046106501494644.jpg","updated_at":"2024-04-30T01:04:40.525Z"}' \
        //   --compressed

        const oldValue = authProfile()

        const updates : AuthProfile = {
          id: user.id,
          handle: handle(),
          dname: dname(),
          avatar_url: avatarUrl(),
          updated_at: isoNow(),
          created_at: oldValue?.created_at || isoNow(),
        }

        // await backendAxios.patch(`/profile/${user.id}`, updates)
        await supabaseAxios.patch('/auth/profile', updates, {
          headers: {
            prefer: POSTGREST_PREFER_HEADER,
          },
        })

        updateAuthProfileValue({
          ...authProfile(),
          ...updates,
        })
        // let { error } = await supabase.from('profiles').upsert(updates)

        // if (error) {
        //   throw error
        // }
      }
    } catch (error) {
      console.error(error)
    } finally {
      loading.end()
    }
  }

  return (
    <div class="container w-24-rem" style={{"max-height":"80vh"}}>
      <Breadcrumbs>
        <A href="/auth/profile">Profile</A>
      </Breadcrumbs>
      <form onSubmit={updateProfile}>
        <FormGroup>
          <EditAvatar
            url={avatarUrl()}
            size={150}
            onUpload={(e: Event, url: string) => {
              setAvatarUrl(url)
              updateProfile(e)
            }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Email</FormLabel>
          <input
            type="email"
            disabled
            class="form-control"
            value={session()?.user?.email || ''}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Handle</FormLabel>
          <input
            type="text"
            class="form-control"
            value={handle() || ''}
            onChange={(e) => setHandle(e.currentTarget.value)}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Display Name</FormLabel>
          <input
            type="text"
            class="form-control"
            value={dname() || ''}
            onChange={(e) => setDname(e.currentTarget.value)}
          />
        </FormGroup>
        <FormGroup>
          <button type="submit" class="btn btn-primary" disabled={loading()}>
            <FaSolidFloppyDisk />{' '}
            {loading() ? 'Saving ...' : 'Update profile'}
          </button>
          <Loader signal={loading} />
        </FormGroup>
      </form>
    </div>
  )
}

export default ProfilePage

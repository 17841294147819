import { literal, object, type InferOutput } from 'valibot'
import { deepClone } from '../../../../../deep_clone'
import { Engine } from '../../../../engine/Engine.type'
import { StateSchema, type State } from '../../../State.type'
import { ActionType } from '../ActionType'

export const SetStateActionSchema = object({
  type: literal(ActionType.Map.SetState),
  state: StateSchema,
})

export type SetStateAction = InferOutput<typeof SetStateActionSchema>

export function createSetStateAction(state: State): SetStateAction {
  return {
    type: ActionType.Map.SetState,
    state,
  }
}

export async function handleSetStateAction(engine: Engine, action: SetStateAction): Promise<void> {
  console.warn('use of ActionType.Map.SetState is a code smell')
  engine.state = action.state
  engine.prevState = deepClone(action.state)
}

import { A, useParams } from '@solidjs/router'
import { FaSolidArrowRight } from 'solid-icons/fa'
import { createMemo, For, Match, Show, Switch } from 'solid-js'
import { createMutable } from 'solid-js/store'
import EngineContext from '../../../components/EngineContext'
import SingleSpriteIcon from '../../../components/MapEditor/SingleSpriteIcon'
import NA from '../../../components/Util/na'
import bindEngineToComponent from '../../../lib/canvas/bind_engine_to_component'
import createEngineForUI from '../../../lib/core/engine/createEngineForUI'
import type { Engine } from '../../../lib/core/engine/Engine.type'
import createSampleEntity from '../../../lib/core/entity/create_sample_entity'
import entityTypeMetaList from '../../../lib/core/entity/entityTypeMetaList.generated'
import getMoveCostPair from '../../../lib/core/entity/getMoveCostPair'
import getMoveTypeMetaById from '../../../lib/core/entity/getMoveTypeMetaById'
import { MoveTypeId, type HasMoveTypeId } from '../../../lib/core/entity/move_type_id.enum'
import type { MoveTypeMeta } from '../../../lib/core/entity/MoveTypeMeta.type'
import { EntityTypeMeta, isSpriteTerrain, isSpriteUnit } from '../../../lib/core/EntityTypeMeta'
import type { HasMobility } from '../../../lib/core/has_mobility'
import { toSlug } from '../../../lib/slug'
import { Nullable } from '../../../typescript'
import NotFound from '../../NotFound'

const CodexUnitShowPage = () => {

  // console.log('CodexUnitShowPage')

  const params = useParams()

  const unit = createMemo<Nullable<EntityTypeMeta>>(() => {
    // console.log(toSlug(params.unit))
    // console.log(entityTypeMetaList)
    // console.log(entityTypeMetaList.map((sprite: EntityType) => sprite?.dname))
    // console.log(entityTypeMetaList.map((sprite: EntityType) => toSlug(sprite?.dname)))
    // console.log(entityTypeMetaList.map((sprite: EntityType) => sprite && isSpriteUnit(sprite) && toSlug(sprite.dname) === toSlug(params.unit)))
    return entityTypeMetaList.find((entityTypeMeta: EntityTypeMeta) => entityTypeMeta && isSpriteUnit(entityTypeMeta) && toSlug(entityTypeMeta.dname) === toSlug(params.unit))
  })
  // createEffect(() => {
  //   console.log('unit', unit())
  // })
  // createEffect(() => {
  //   console.log('params.unit', params.unit)
  // })

  const entityTypes = entityTypeMetaList.filter((sprite: EntityTypeMeta) => sprite && isSpriteTerrain(sprite))

  const engine: Engine = createMutable(createEngineForUI())
  // need sample player for UI engine
  const samplePlayerId = createMemo<number>(() => {
    return engine.state.players[0].id
  })
  const moveTypeMeta = createMemo<Nullable<MoveTypeMeta>>(() => {
    return getMoveTypeMetaById((unit()?.entDefaults as HasMoveTypeId)?.mtype_id as MoveTypeId)
  })

  bindEngineToComponent(engine)


  return (<Show when={unit()} fallback={NotFound()} children={(unit) => {
    return (<>
      <div>
        <h2>
          <A href="/codex">Codex</A>{' - '}
          <A href="/codex/unit">Units</A>{' - '}
          {unit()?.dname}
        </h2>
        <A href={`/codex/unit/${toSlug(unit()?.dname)}`}>
          {unit()?.dname}
        </A>
        <table>
          <tbody>
            <tr>
              <th>Movement Type:</th>
              <td class="text-end">{moveTypeMeta()?.dname || NA()}</td>
            </tr>
            <tr>
              <th>Movement Points:</th>
              <td class="text-end">{(unit()?.entDefaults as HasMobility).mobility || NA()}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <EngineContext.Provider value={engine}>
        <div>
          <table class="table table-sm w-auto">
            <thead>
              <tr>
                <th colspan="2">Terrain</th>
                <th class="text-end">Move Cost</th>
                <th class="text-end">Defense</th>
              </tr>
            </thead>
            <tbody>
              <For each={entityTypes} fallback={<div class="text-muted">Empty List</div>}>
                {(entityType: EntityTypeMeta) => {
                  // const entDefaults = entityType.entDefaults
                  // const mtype: Nullable<MoveTypeMeta> = getMoveTypeMetaById((entDefaults as HasMoveTypeId).mtype_id)
                  const moveCostPair = getMoveCostPair(entityType.id, moveTypeMeta()?.id as MoveTypeId)

                  return <tr>
                    <td>
                      <SingleSpriteIcon entity={createSampleEntity(unit(), samplePlayerId())} />
                      <FaSolidArrowRight/>
                      <SingleSpriteIcon entity={createSampleEntity(entityType, samplePlayerId())} />
                    </td>
                    <td>
                      <A href={`/codex/terrain/${toSlug(entityType.dname)}`}>
                        {entityType.dname}
                      </A>
                    </td>
                    <td class="text-end">
                      <Switch fallback={NA()}>
                        <Match when={moveCostPair?.block}
                          children={<span class="text-muted" aria-label="blocked">
                            {NA()}
                          </span>}
                        />
                        <Match when={(moveCostPair?.value as number) >= 0}
                          children={moveCostPair?.value as number}
                        />
                      </Switch>
                    </td>
                    <td class="text-end">
                      {(entityType.defense != null)
                        ? entityType.defense
                        : NA()}
                    </td>
                  </tr>
                }}
              </For>
            </tbody>
          </table>
        </div>
      </EngineContext.Provider>
    </>)
  }}/>)
}

export default CodexUnitShowPage
import { captureException } from '@sentry/browser'
import { createEffect, on, onCleanup } from 'solid-js'
import { modifyMutable, produce } from 'solid-js/store'
import type { Engine } from '../../lib/core/engine/Engine.type'
import type Action from '../../lib/core/state/flux/action/Action.type'
import dispatchFromRemote from '../../lib/core/state/flux/dispatchFromRemote'
import { StateType } from '../../lib/core/state/state_type.enum'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import type { Nullable } from '../../typescript'
import getAccessToken from '../shared/session/getAccessToken'

export default function registerServerSentEvents(engine: Engine) {
  // if (import.meta.env.VITE_MERCURE_ENDPOINT) {
  // eventSource = createMemo()
  let eventSource: EventSource | null = null

  function closeEventSourceIfExists() {
    // console.log('closeEventSourceIfExists', { exists: !!eventSource })
    if (eventSource) {
      eventSource.close()
    }
  }

  onCleanup(closeEventSourceIfExists)

  const stateId = () => engine.state.online?.id

  // eventSource.onmessage = (message) => {
  //   const data: object = JSON.parse(message.data)
  //   console.log('es.onmessage', data)
  //   messages.push(data)
  // }

  function onmessage(event: MessageEvent) {
    console.log('New message from server:', event.data)
  }

  function onAction(event: MessageEvent) {
    console.log('New Action from server:', event.data)
    const action: Action = JSON.parse(event.data)
    // dispatchFromRemote will validate
    // const validatedAction = parse(ActionUnionSchema, event.data)
    modifyMutable(
      engine,
      produce(() => {
        dispatchFromRemote(engine, action)
      })
    )
  }

  function onerror(error: Event) {
    console.error('SSE error:', error)
    captureException(error)
    if (eventSource?.readyState === EventSource.CLOSED) {
      // console.log('will reconnect soon...')
      setTimeout(() => {
        // console.log('reconnecting...')
        if (eventSource?.readyState === EventSource.CLOSED) {
          createOrReplaceEventSource(stateId())
        } else {
          console.warn('already reconnected?')
        }
      }, 5000)
    }
  }

  function onopen() {
    // console.log('SSE connection opened')
  }

  async function createOrReplaceEventSource(stateId: Nullable<StateOnlineId>) {
    // console.log('createOrReplaceEventSource', { stateId })
    closeEventSourceIfExists()
    const access_token = await getAccessToken()
    if (stateId && engine.state.type !== StateType.Replay && access_token) {
      const hubURL = new URL('/api/sse', window.location.href)
      hubURL.searchParams.append('access_token', access_token)
      hubURL.searchParams.append('state', stateId as unknown as string)

      eventSource = new EventSource(hubURL)
      eventSource.onmessage = onmessage
      eventSource.onerror = onerror
      eventSource.onopen = onopen
      eventSource.addEventListener('Action', onAction)
    }
  }

  createEffect(on(stateId, createOrReplaceEventSource))
}

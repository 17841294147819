import { Entity } from '.'
import type { HasAttackedThisTurn } from '../AttackedThisTurn'
import type { HasWaitedThisTurn } from '../WaitedThisTurn'
import type { HasWasBuiltThisTurn } from '../WasBuiltThisTurn'

export default function shouldEntityShowFadedFrames(entity: Entity): boolean {
  if ((entity as HasWaitedThisTurn).waitedThisTurn) {
    return true
  }
  if ((entity as HasWasBuiltThisTurn).builtThisTurn) {
    return true
  }
  if ((entity as HasAttackedThisTurn).attackedThisTurn) {
    return true
  }
  return 'mobility' in entity && entity.mobility <= 0
}

import { modifyMutable, produce } from 'solid-js/store'
import { Engine } from './core/engine/Engine.type'
import onWindowEvent from './onWindowEvent'

// function computeLevelOfDetail (w: number, h: number): number {
//   const v = min(w, h)
//   if (v > 1200) { return 3 }
//   if (v > 550) { return 2 }
//   return 1
// }

export default function bindViewCtxToWindow(engine: Engine) {
  // const dirty = engine.cache[1]
  const onResize = () => {
    modifyMutable(
      engine,
      produce((engine: Engine) => {
        const { viewCtx } = engine
        viewCtx.renderWidthPx = window.innerWidth
        viewCtx.renderHeightPx = window.innerHeight
        // viewCtx.level_of_detail = computeLevelOfDetail(viewCtx.renderWidthPx, viewCtx.renderHeightPx)
        // dirty(EngineCache.ViewCtx)
      })
    )
  }
  onWindowEvent('resize', onResize)

  // manually trigger the effect of the resize listener
  onResize()
}

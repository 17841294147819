import { Accessor, Setter, createEffect, createSignal, onCleanup } from 'solid-js'
import { supabaseAxios } from '../../../axios'

type AvatarUrlString = string
type AvatarBlobString = string

// no idea why, but not manually specifying this type caused linting errors
type PathToObjectUrlSignal = [Accessor<AvatarUrlString | null>, Setter<AvatarBlobString | null>]

/**
 * this is an unusual signal,
 * it's actually 2 signals
 * but the second is a derived async value of the first
 */
export default function createPathToObjectUrlSignal(
  bucket: string,
  initialValue: AvatarUrlString | null = null
): PathToObjectUrlSignal {
  const [avatarUrl, setAvatarUrl] = createSignal<AvatarUrlString | null>(initialValue)
  const [avatarBlob, setAvatarBlob] = createSignal<AvatarBlobString | null>(null)

  createEffect(() => {
    const path = avatarUrl()
    if (path) {
      downloadImage(path)
    } else {
      setAvatarBlob(null)
    }
  })

  // /rest/v1/profiles
  // {"handle":"a_handle3","dname":"MyDisplayName","avatar_url":"0.9289087208492062.jpeg"}
  // curl 'https://hwylrqkmnilaijnprsat.supabase.co/storage/v1/object/avatars/0.9289087208492062.jpeg' --compressed
  // curl 'https://hwylrqkmnilaijnprsat.supabase.co/storage/v1/object/avatar/0.9289087208492062.jpeg' \
  //   -H 'authority: hwylrqkmnilaijnprsat.supabase.co' \
  //   -H 'accept: application/vnd.pgrst.object+json' \
  //   -H 'accept-language: en-US,en;q=0.9' \
  //   -H 'apikey: REDACTED' \
  //   -H 'authorization: Bearer REDACTED' \
  //   -H 'origin: http://localhost:3000' \
  //   -H 'referer: http://localhost:3000/' \
  //   -H 'sec-ch-ua: "Chromium";v="113", "Not-A.Brand";v="24"' \
  //   -H 'sec-ch-ua-mobile: ?0' \
  //   -H 'sec-ch-ua-platform: "macOS"' \
  //   -H 'sec-fetch-dest: empty' \
  //   -H 'sec-fetch-mode: cors' \
  //   -H 'sec-fetch-site: cross-site' \
  //   -H 'user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Iron Safari/537.36' \
  //   --compressed

  const downloadImage = async (path: string) => {
    // console.log(path)
    try {
      // /storage/v1/object/avatars/0.9046106501494644.jpg
      const response = await supabaseAxios.get(`/storage/v1/object/${bucket}/${path}`, {
        responseType: 'blob',
      })
      const url = URL.createObjectURL(response.data)
      setAvatarBlob((prev) => {
        if (prev) {
          URL.revokeObjectURL(prev)
        }
        return url
      })
    } catch (error) {
      console.error(error)
    }
  }

  onCleanup(() => {
    const url = avatarUrl()
    if (url) {
      URL.revokeObjectURL(url)
    }
  })

  return [avatarBlob, setAvatarUrl]
}

import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { Entity } from '../../../../entity/index'
import { EraseTileOption, EraseTileOptionSchema } from '../../../../map_editor/erase_map_tile'
import {
  TilePositionXY,
  TilePositionXYSchema,
} from '../../../../tile_position_xy/TilePositionXY.type'
import { ActionType } from '../ActionType'
import calculateSelectedPositionEnts from '../../../../entity/calculateSelectedPositionEnts'

export const EraseTileActionSchema = object({
  type: literal(ActionType.Map.EraseTile),
  options: EraseTileOptionSchema,
  position: TilePositionXYSchema,
})

export type EraseTileAction = InferOutput<typeof EraseTileActionSchema>

export function createEraseTileAction(
  options: EraseTileOption,
  position: TilePositionXY
): EraseTileAction {
  return {
    type: ActionType.Map.EraseTile,
    options,
    position,
  }
}

export async function handleEraseTileAction(
  engine: Engine,
  action: EraseTileAction
): Promise<void> {
  const { options, position } = action

  const { ents } = engine.state
  const x = position.x
  const y = position.y
  // console.log('handleEraseTileAction', options, position)
  const entity_layer_ids = options.entity_layer_ids
  const a: Array<Entity> = []
  for (let index = 0, ent_len = ents.length; index < ent_len; index++) {
    const ent = ents[index]
    // console.log(ent.layer_id, ent.x, ent.y)
    if (x == ent.x && y == ent.y && entity_layer_ids.includes(ent.layer_id)) {
      // do nothing, we're deleting this tile
      // console.log(ent.layer_id, ent.x, ent.y)
    } else {
      a.push(ents[index])
    }
  }

  engine.state.ents = a
  calculateSelectedPositionEnts(engine)
}

import { array, literal, number, object, optional, type InferOutput } from "valibot"
import { AmmoSchema } from "../ammo.type"
import { ArmorSchema } from "../Armor.type"
import { AttackedThisTurnSchema } from "../AttackedThisTurn"
import { FuelSchema } from "../fuel.type"
import { HPSchema } from "../hp.type"
import { MobilityNumberSchema } from "../MobilityNumber.type"
import { PlayerIdOrNullSchema } from "../player/PlayerIdOrNull"
import { VisionSchema } from "../vision.type"
import { WaitedThisTurnSchema } from "../WaitedThisTurn"
import { WasBuiltThisTurnSchema } from "../WasBuiltThisTurn"
import { EntityLayerIdSchema } from "./entity_layer_id.enum"
import { EntityTypeId } from "./entity_type_id.enum"
import { EntityIdSchema } from "./id.type"
import { MoveTypeIdSchema } from "./move_type_id.enum"
import { WeaponSchema } from "./Weapon/Weapon.type"

export const BaseEntitySchema = object({
  etype_id: literal(EntityTypeId.LightTank),
  layer_id: EntityLayerIdSchema,
  id: EntityIdSchema,
  player_id: optional(PlayerIdOrNullSchema),
  hp: optional(HPSchema),
  fuel: optional(FuelSchema),
  vision: optional(VisionSchema),
  ammo: optional(AmmoSchema),
  armor: optional(ArmorSchema),
  x: optional(number()),
  y: optional(number()),
  mobility: optional(MobilityNumberSchema),
  mtype_id: optional(MoveTypeIdSchema),
  builtThisTurn: WasBuiltThisTurnSchema,
  attackedThisTurn: AttackedThisTurnSchema,
  waitedThisTurn: WaitedThisTurnSchema,
  weapons: optional(array(WeaponSchema)),
  // transport and cargo
  cargo: optional(array(EntityIdSchema)),
  taxi_id: optional(EntityIdSchema),
})

export type BaseEntity = InferOutput<typeof BaseEntitySchema>

import { Entity } from '.'
import type { HasAttackedThisTurn } from '../AttackedThisTurn'
import type { HasMobility } from '../has_mobility'
import type { HasWasBuiltThisTurn } from '../WasBuiltThisTurn'

export default function isEntityHasMovesLeft(entity: Entity): entity is Entity & HasMobility {
  if ((entity as HasAttackedThisTurn).attackedThisTurn) {
    return false
  }
  if ((entity as HasWasBuiltThisTurn).builtThisTurn) {
    return false
  }
  return 'mobility' in entity && entity.mobility > 0
}

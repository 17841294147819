// import type { HasPrice } from '../HasPrice'
import { variant } from 'valibot'
import { entitySchemaList } from './entityTypeMetaList.generated'

// this array is built correctly, but typescript does not understand it correctly.
const entitySchemaForFactoryList = entitySchemaList.filter((entitySchema) => {
  return 'builtThisTurn' in entitySchema.entries
})

// typescript says this is len=21, but it's actually len=10
// console.log('entitySchemaForFactoryList', entitySchemaForFactoryList)

export const EntityForFactorySchema = variant('etype_id', entitySchemaForFactoryList)

// export type EntityForFactory = InferOutput<typeof EntityForFactorySchema>

// export type EntityForFactoryUnion = Extract<EntitySchemaUnion, HasWasBuiltThisTurn>

export default entitySchemaForFactoryList

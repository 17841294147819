// @link https://validatejs.org/#validate-single

import { SLUG_MAX_LEN, SLUG_MIN_LEN } from './slug'

const handle = {
  type: 'string',
  length: {
    minimum: SLUG_MIN_LEN,
    maxiumum: SLUG_MAX_LEN,
  },
  format: {
    pattern: '[a-zA-Z0-9-]+',
    flags: 'g',
    message: 'can only contain alphanumeric characters and -',
  },
} as const

export const DNAME_MINIMUM = 3
export const DNAME_MAXIMUM = 40

const dname = {
  type: 'string',
  length: {
    minimum: DNAME_MINIMUM,
    maxiumum: DNAME_MAXIMUM,
  },
} as const

export const DESCRIPTION_MINIMUM = 0
export const DESCRIPTION_MAXIMUM = 255

const desc = {
  type: 'string',
  length: {
    minimum: DESCRIPTION_MINIMUM,
    maxiumum: DESCRIPTION_MAXIMUM,
  },
} as const

export const GAME_PASSPHRASE_MINIMUM = 10
export const GAME_PASSPHRASE_MAXIMUM = 255

const gamePassphrase = {
  // type: 'string',
  length: {
    minimum: GAME_PASSPHRASE_MINIMUM,
    maxiumum: GAME_PASSPHRASE_MAXIMUM,
  },
} as const

export const profileValidationRules = {
  handle,
} as const

export const MAP_DEFAULT_WIDTH = 10
export const MAP_DEFAULT_HEIGHT = 10
// AWBW uses 50
// Civ2 also has a W times H must be less than X
export const MAP_MIN_SIZE = 8
export const MAP_MAX_SIZE = 32

const mapSizeDimension = {
  type: 'number',
  numericality: {
    onlyInteger: true,
    greaterThanOrEqualTo: MAP_MIN_SIZE,
    lessThanOrEqualTo: MAP_MAX_SIZE,
    noStrings: true,
  },
} as const

export const mapValidationRules = {
  dname,
  desc,
  width: mapSizeDimension,
  height: mapSizeDimension,
} as const

export const gameValidationRules = {
  dname,
  desc,
  passphrase: gamePassphrase,
} as const

export type ErrorBag = {
  [key: string]: Array<string>
}

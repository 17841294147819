import { InferOutput, literal, object } from 'valibot'
import { EntityTypeMeta, entityTypeDefaults } from '../../EntityTypeMeta'
import { HasXYSchema } from '../../has_xy'
import { EntityLayerId } from '../entity_layer_id.enum'
import { EntityTypeId } from '../entity_type_id.enum'
import { EntityIdSchema } from '../id.type'

const entDefaults = {
  etype_id: EntityTypeId.Mountain,
  layer_id: EntityLayerId.TerrainL2,
} as const

export const MountainEntitySchema = object({
  etype_id: literal(EntityTypeId.Mountain),
  layer_id: literal(EntityLayerId.TerrainL2),
  id: EntityIdSchema,
  ...HasXYSchema.entries,
})

export type MountainEntity = InferOutput<typeof MountainEntitySchema>

export const MountainEntityTypeMeta: EntityTypeMeta = {
  ...entityTypeDefaults,
  id: EntityTypeId.Mountain,
  dname: 'Mountain',
  layer_id: EntityLayerId.TerrainL2,
  defense: 4,
  visionBonus: 3,
  blocksVision: true,
  entDefaults,
}

export function createMountainEntity(id: number, x: number, y: number): MountainEntity {
  return {
    ...entDefaults,
    id,
    x,
    y,
  }
}

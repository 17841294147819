import { Engine } from '../../core/engine/Engine.type'
import { SelectedToolId } from '../../core/map_editor/SelectedToolId.enum'
import { createSetStateAction } from '../../core/state/flux/action/Map/SetStateAction'
import dispatchClient from '../../core/state/flux/dispatchClient'
import saveStateChanges from '../../core/state/save_state_changes'
import onWindowEvent from '../../onWindowEvent'
import preventDefault from './prevent_default'

export default function registerMapEditKeyboardEvents(engine: Engine, isMapEdit: boolean) {
  // const dirty = engine.cache[1]
  // const ab = createAlertBagStore()
  function onMapEditKeyDown(event: KeyboardEvent) {
    // console.log('onMapEditKeyDown', event)
    if (shouldIgnoreHotKeys(event)) {
      return
    }
    if (event.key === 's') {
      if (event.metaKey || event.ctrlKey) {
        // console.log('Save Hotkey!')
        preventDefault(event)
        saveStateChanges(engine)
        dispatchClient(engine, createSetStateAction(engine.state))
        return
      }
    }
    if (isMapEdit) {
      if (event.key === 'z') {
        if (event.metaKey || event.ctrlKey) {
          preventDefault(event)
          if (event.shiftKey) {
            // console.log('Redo Event!')
          } else {
            // console.log('Undo Event!')
          }
          return
        }
      }
      if (event.key === 'p') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.Paint
        return
      }
      if (event.key === 'e') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.Erase
        return
      }
      if (event.key === 'f') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.File
        return
      }
      if (event.key === 'l') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.Players
        return
      }
      if (event.key === 'i') {
        preventDefault(event)
        engine.selectedTool = SelectedToolId.Inspect
        return
      }
    }

    // when it's possible for Ctrl - Z to undo this
    // if (event.key === 'r') {
    //   if (event.shiftKey && (event.metaKey || event.ctrlKey)) {
    //     console.log('Revert to PrevState!')
    //     preventDefault(event)
    //     engine.state = structuredClone(unwrap(engine.prevState))
    //     return
    //   }
    // }
  }

  function onMapEditKeyUp() {
    // console.log('onMapEditKeyUp', event)
    // if (event.key === 's') {
    //   if (event.metaKey || event.ctrlKey) {
    //     console.log('Save Hotkey!')
    //     preventDefault(event)
    //     saveStateChanges(engine)
    //     return
    //   }
    // }
  }

  onWindowEvent('keyup', onMapEditKeyUp)
  onWindowEvent('keydown', onMapEditKeyDown)
}

function shouldIgnoreHotKeys(event: KeyboardEvent): boolean {
  if (event.metaKey || event.ctrlKey) {
    return false
  }

  const target = event.target as HTMLElement | null
  const targetNodeName = target?.nodeName
  // console.log('targetNodeName', targetNodeName)
  return (targetNodeName === 'INPUT' ||
    targetNodeName === 'TEXTAREA' ||
    target?.isContentEditable) as boolean
}

import { GameRowValue } from '@sg/backend/src/lib/db/getGameOr404.ts'
import { Component, onMount } from 'solid-js'
import { createMutable, modifyMutable, produce, unwrap } from 'solid-js/store'
import toast from 'solid-toast'
import bindViewCtxToWindow from '../lib/bindViewCtxToWindow'
import bindEngineToComponent from '../lib/canvas/bind_engine_to_component'
import addDrawMovementGridCanvas from '../lib/canvas/layer/addDrawMovementGridCanvas'
import addBgGradientCanvas from '../lib/canvas/layer/bg_gradient'
import addDrawEntsByKindCanvas from '../lib/canvas/layer/ents_by_type'
import addTerrainCursorCanvasOld from '../lib/canvas/layer/terrain_cursor/add_canvas'
import addCursorCanvas from '../lib/canvas/layer/terrain_cursor/addCursorCanvas'
import calculateMapWidthHeightPxTileSize from '../lib/core/calculateMapWidthHeightPx'
import calculateTileSize from '../lib/core/calculateTileSize'
import { centerPan } from '../lib/core/center_pan'
import createEngineForGamePlay from '../lib/core/engine/createEngineForGamePlay'
import { Engine } from '../lib/core/engine/Engine.type'
import { EntityLayerId } from '../lib/core/entity/entity_layer_id.enum'
import { SelectedToolId } from '../lib/core/map_editor/SelectedToolId.enum'
import { createIsStateDirtyMemo } from '../lib/core/state/create_is_state_dirty_memo'
import { StateType } from '../lib/core/state/state_type.enum'
import { deepClone } from '../lib/deep_clone'
import makeCanvasFullViewport from '../lib/dom/canvas/makeCanvasFullViewport'
import registerContextMenuEvent from '../lib/dom/event/context_menu'
import registerMouseClickEvent from '../lib/dom/event/mouse_click'
import registerMouseDownEvent from '../lib/dom/event/mouse_down'
import registerMouseMoveEvent from '../lib/dom/event/mouse_move'
import registerMouseUpEvent from '../lib/dom/event/mouse_up'
import registerGamePlayKeyboardEvents from '../lib/dom/event/registerGamePlayKeyboardEvents'
import registerTouchEventsV2 from '../lib/dom/event/registerTouchEventsV2'
import registerWheelEvent from '../lib/dom/event/wheel'
import createRedirectIfWrongStateTypeEffect from '../rx/effect/createRedirectIfWrongStateTypeEffect'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'
import useCornerWidgetEngine from '../rx/shared/CornerWidget/useCornerWidgetEngine'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'
import registerServerSentEvents from '../rx/sse/registerServerSentEvents'
import useConfirmLeaveWithUnsavedChanges from '../rx/use/useConfirmLeaveWithUnsavedChanges'
import EngineContext from './EngineContext'
import ZoomCardWidget from './Hud/ZoomCardWidget'

interface Props {
  // session: AuthSession,
  row: GameRowValue,
}

const GamePlayCanvas: Component<Props> = (props) => {
  const engine: Engine = createMutable<Engine>(createEngineForGamePlay())

  onMount(() => {
    modifyMutable(engine, produce((engine) => {
      engine.state = deepClone(props.row.data)
      engine.prevState = deepClone(props.row.data)
    }))
  })

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  function getEngine() { return unwrap(engine) }
  (window as unknown as { getEngine: () => Engine }).getEngine = getEngine

  bindViewCtxToWindow(engine)

  addBgGradientCanvas(engine)
  // addTerrainGridCanvas(engine)
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL1])
  addDrawEntsByKindCanvas(engine, [EntityLayerId.TerrainL2])
  addDrawMovementGridCanvas(engine)
  addDrawEntsByKindCanvas(engine, [EntityLayerId.Unit2])

  // addDrawOffMapCloudsCanvas(engine)
  addTerrainCursorCanvasOld(engine)
  addCursorCanvas(engine)

  registerMouseMoveEvent(engine)
  registerMouseDownEvent(engine)
  registerMouseUpEvent(engine)
  registerMouseClickEvent(engine)
  registerContextMenuEvent(engine)
  registerWheelEvent(engine)
  registerGamePlayKeyboardEvents(engine, true)
  // registerTouchEventsV2(engine)
  
  modifyMutable(engine, produce((engine) => {
    engine.canvasList.forEach(makeCanvasFullViewport)
  }))

  const isStateDirty = createIsStateDirtyMemo(engine)
  useConfirmLeaveWithUnsavedChanges(isStateDirty)

  bindEngineToComponent(engine)

  useCornerWidgetEngine(engine)

  registerServerSentEvents(engine)
  createRedirectIfWrongStateTypeEffect(StateType.Game, () => engine.state.type, ()=>engine.state.online?.id)

  
  // setUp()
  onMount(() => {
    modifyMutable(engine, produce((engine) => {
      engine.toast = toast
      // engine.selectedPosition = { x: 13, y: 9 }
      // calculateSelectedPositionEnts(engine)
      engine.selectedTool = SelectedToolId.Inspect
      engine.viewCtx.zoom = 200
  
      engine.viewCtx.tile_size = calculateTileSize(engine.viewCtx)
      calculateMapWidthHeightPxTileSize(engine)
      centerPan(engine)
    }))
  })

  // setTimeout(() => {
  //   modifyMutable(engine, produce((engine) => {
  //     engine.selectedPosition = coord(1, 1)
  //     calculateSelectedPositionEnts(engine)
  //     engine.hoveredPosition = coord(2, 3)
  //   }))
  // }, 500)
  // setTimeout(() => {
  //   modifyMutable(engine, produce((engine) => {
  //     engine.hoveredPosition = null
  //     engine.draftMove!.destPosition = coord(2, 3)
  //   }))
  // }, 1000)

  // let prevSelectedTool : SelectedToolId | null = null
  // createEffect(() => {
  //   // console.log('Effect', 'engine.selectedTool', engine.selectedTool)
  //   if (prevSelectedTool === SelectedToolId.MoveUnit && engine.selectedTool === SelectedToolId.Inspect) {
  //     throw new Error('Move -> Inspect')
  //   }
  //   prevSelectedTool = engine.selectedTool
  // })
  // createEffect(() => {
  //   // eslint-disable-next-line solid/reactivity
  //   const { draftMove } = engine
  //   if (draftMove) {
  //     trackDeep(draftMove)
  //   }
  //   console.log('Effect', 'engine.draftMove', unwrap(draftMove))
  // })
  // createEffect(() => {
  //   const { pan_x, pan_y } = engine.viewCtx
  //   console.log('Effect.viewCtx', { pan_x, pan_y })
  // })

  return <EngineContext.Provider value={engine}>
    <div ref={(ref : HTMLElement) => {
      // console.log('ref', ref)
      if (ref) {
        registerTouchEventsV2(engine, ref)
      }
    }}>
      {engine.canvasList}
      <ZoomCardWidget/>
    </div>
  </EngineContext.Provider>
}

export default GamePlayCanvas

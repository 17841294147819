import { DisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { literal, object, optional, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { Player } from '../../../../player/Player.type'
import { PlayerId, PlayerIdSchema } from '../../../../player/PlayerId'
import { assign } from '../../../../util/Object'
import { ActionType } from '../ActionType'

export const PatchPlayerActionDataSchema = object({
  dname: optional(DisplayNameSchema),
})

export type PatchPlayerActionData = InferOutput<typeof PatchPlayerActionDataSchema>

export const PatchPlayerActionSchema = object({
  type: literal(ActionType.Map.PatchPlayer),
  player_id: PlayerIdSchema,
  data: PatchPlayerActionDataSchema,
})

export type PatchPlayerAction = InferOutput<typeof PatchPlayerActionSchema>

export function createPatchPlayerAction(
  player_id: PlayerId,
  data: PatchPlayerActionData
): PatchPlayerAction {
  return {
    type: ActionType.Map.PatchPlayer,
    player_id,
    data,
  }
}

export async function handlePatchPlayerAction(
  engine: Engine,
  action: PatchPlayerAction
): Promise<void> {
  const { player_id, data } = action
  engine.state.players.forEach((player: Player): void => {
    if (player.id === player_id) {
      assign(player, data)
    }
  })
}

import type { Nullable } from '../../../typescript'
import isNil from '../../ldsh/isNil'
import { floor } from '../util/math'
import type { PlayerMoney } from './PlayerMoney.type'

const moneyIntl = new Intl.NumberFormat()

// <span class="text-muted ps-1">₵</span>
export default function formatGameMoney(value: Nullable<PlayerMoney>): string {
  if (isNil(value)) {
    return '--'
  }
  value = floor(value)
  return moneyIntl.format(value)
}

import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import calculateSelectedPositionEnts from '../../../../entity/calculateSelectedPositionEnts'
import { Entity } from '../../../../entity/index'
import type { HasPlayerId } from '../../../../player/has_player_id'
import { Player } from '../../../../player/Player.type'
import { PlayerId, PlayerIdSchema } from '../../../../player/PlayerId'
import { ActionType } from '../ActionType'

export const DeletePlayerActionSchema = object({
  type: literal(ActionType.Map.DeletePlayer),
  playerId: PlayerIdSchema,
})

export type DeletePlayerAction = InferOutput<typeof DeletePlayerActionSchema>

export function createDeletePlayerAction(playerId: PlayerId): DeletePlayerAction {
  return {
    type: ActionType.Map.DeletePlayer,
    playerId,
  }
}

export async function handleDeletePlayerAction(
  engine: Engine,
  action: DeletePlayerAction
): Promise<void> {
  const { playerId } = action
  const { state } = engine

  state.ents = state.ents.filter((ent: Entity) => (ent as HasPlayerId).player_id !== playerId)
  state.players = state.players.filter((p: Player) => p.id && p.id !== playerId)
  calculateSelectedPositionEnts(engine)
}


import {
  type IndexMapQuery
} from '@sg/backend/src/api/st1/map/handleIndexMap'
import { A, useSearchParams } from '@solidjs/router'
import { Card } from 'solid-bootstrap'
import { FaSolidPencil, FaSolidPlus } from 'solid-icons/fa'
import { For, Show, Suspense, createSignal } from 'solid-js'
import { createMutable, produce } from 'solid-js/store'
import AlertBag from '../../components/AlertBag'
import Breadcrumbs from '../../components/Breadcrumb/Breadcrumbs'
import DeleteButton from '../../components/Btn/DeleteButton'
import PublishButton from '../../components/Btn/PublishButton'
import CardText from '../../components/Card/CardText'
import CardTitle from '../../components/Card/CardTitle'
import EngineContext from '../../components/EngineContext'
import Loader from '../../components/Loader'
import NewGameIcon from '../../components/MapEditor/NewGameIcon'
import MapShowCanvas from '../../components/MapShowCanvas'
import MapSizeText from '../../components/Text/MapSizeText'
import EmSmallTextMuted from '../../components/Util/EmSmallTextMuted'
import bindEngineToComponent from '../../lib/canvas/bind_engine_to_component'
import createEngineForUI from '../../lib/core/engine/createEngineForUI'
import { Engine } from '../../lib/core/engine/Engine.type'
import { deepClone } from '../../lib/deep_clone'
import createMapCollectionResource from '../../rx/resource/createMapCollectionResource'
import createAuthProfileSignal from '../../rx/shared/profile/createAuthProfileSignal'
import { createLoadingSignal, registerLoadingResource } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, registerAlertBagResource } from '../../rx/store/create_alert_bag_store'
import type { Nullable } from '../../typescript'
import createSetEngineAuthPlayerIdFromAuthProfileSignalEffect from '../../rx/effect/createSetEngineAuthPlayerIdFromAuthProfileSignalEffect'

const IndexMapPage = () => {
  const loading = createLoadingSignal()
  const ab = createAlertBagStore()
  const [searchParams] = useSearchParams<IndexMapQuery>()

  const mapCollectionResource = createMapCollectionResource(() => deepClone(searchParams))
  registerLoadingResource(loading, mapCollectionResource)
  registerAlertBagResource(ab, mapCollectionResource)

  const engine: Engine = createMutable(createEngineForUI())

  const [authProfile] = createAuthProfileSignal()
  createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(engine, authProfile)

  bindEngineToComponent(engine)

  return (
    <EngineContext.Provider value={engine}>
    <div>
      <Breadcrumbs />
      <h2>Map List</h2>
      <div class="btn-group">
        <Show when={authProfile()?.id && searchParams.owner_profile_uuid !== authProfile()?.id}>
          <A href={`/maps?owner_profile_uuid=${authProfile()?.id}`} class="btn btn-primary">
            My Maps
          </A>
        </Show>
        <Show when={!(authProfile()?.id && searchParams.owner_profile_uuid !== authProfile()?.id)}>
          <A href="/maps" class="btn btn-primary">
            All Published Maps
          </A>
        </Show>
        <A href="/maps/create" class="btn btn-success">
          <FaSolidPlus /> New Map
        </A>
      </div>
      <Loader signal={loading} />
      <AlertBag store={ab} />
      <Suspense fallback={<div class="text-muted">Loading...</div>}>
        <Show when={mapCollectionResource[0]()}>{(stateCollection) =>
          <>
            <For each={stateCollection()} fallback={<div class="text-muted">Empty List</div>}>
              {(stateRow) => {
                // console.log('stateRow', stateRow)
                const [map, setMap] = createSignal(stateRow)
                // function map(): MapItemResourceValue {
                //   return item
                // }

                // createEffect(() => {
                //   console.log('map.deleted_at', map().deleted_at, map())
                // })
                // createEffect(() => {
                //   console.log('map.deleted_at', item.deleted_at)
                // })

                const stateId = (): Nullable<number> => map().data.map.id

                return <Card>
                  <div class="card-body clearfix">
                    <div class="float-end">
                      <MapShowCanvas state={map().data} width={400} height={400} />
                    </div>
                    <CardTitle>
                      <A href={`/map?map_id=${stateId()}`}
                        aria-label="Goto Map"
                        title="Goto Map"
                      >{map().data.map.dname || stateId()}</A>
                      {' '}
                      <A href={`/map/edit?map_id=${stateId()}`}
                        class="btn btn-dark btn-sm border-white"
                        aria-label="Edit Map"
                        title="Edit Map"
                      >
                        <FaSolidPencil />
                      </A>
                      {' '}
                      <DeleteButton
                        noun='Map' disabled={loading()}
                        item={stateRow} ab={ab}
                        onChange={(newValue) => {
                          setMap(produce((stateRow) => {
                            stateRow.deleted_at = newValue
                          }))
                        }}
                      />
                      {' '}
                      <PublishButton
                        noun='Map' disabled={loading()}
                        item={stateRow} ab={ab}
                        onChange={(newValue) => {
                          setMap(produce((stateRow) => {
                            stateRow.published_at = newValue
                          }))
                        }}
                      />
                      {' '}
                      <A href={`/lobby/create?map_id=${stateId()}`}
                        class="btn btn-success btn-sm"
                        aria-label="Edit Map"
                        title="Edit Map"
                      >
                        <NewGameIcon/>
                        {/* {' '}
                        <RiDeviceGamepadLine />
                        <FaSolidGamepad/>
                        <FaSolidPuzzlePiece/>
                        <FaSolidCirclePlus/>
                        <FaSolidCube/> */}
                        {' '}
                        New Game
                      </A>
                    </CardTitle>
                    <CardText>
                      {map().data.map.bio || EmSmallTextMuted('no description')}
                    </CardText>
                    <CardText>
                      Size: <MapSizeText state={map().data} />
                    </CardText>
                  </div>
                </Card>
              }}
            </For>
          </>
        }</Show>
      </Suspense>
      </div>
    </EngineContext.Provider>
  )
}

export default IndexMapPage
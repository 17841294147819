import { Player } from './Player.type'
import { PlayerKind } from './PlayerKind'

export default function resetPlayerSlotToOpen(player: Player) {
  player.kind = PlayerKind.OpenSlot
  player.is_local = null
  player.profile_id = null
  player.invite_id = null
  player.bot_id = null
}

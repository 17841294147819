import { array, check, InferOutput, maxLength, nullable, pipe, rawCheck } from 'valibot'
import { MAP_MAX_SIZE } from '../../../lib/validation'
import { EntitySchema, type Entity } from '../entity'
import type { BaseEntity } from '../entity/BaseEntity'
import type { HasTaxiID } from '../entity/HasTaxiID'
import type { EntityId } from '../entity/id.type'
import { samePosition } from '../tile_position_xy/samePosition'
import idsAreUnique from './validation/idsAreUnique'
import verifyRelationshipBetweenTransportsAndCargo from './verifyRelationshipBetweenTransportsAndCargo'

// I guess "too many entities" is an attack vector
// ATM plain + forest + APC(Infantry) + TransportCopter(Infantry)
// on literally all tiles is a about 6 per tile
const MAX_ENTITY_LIST_SIZE = MAP_MAX_SIZE * MAP_MAX_SIZE * 5

export const EntityListSchema = pipe(
  array(EntitySchema),
  // minLength(0),
  //
  maxLength(MAX_ENTITY_LIST_SIZE),
  check(idsAreUnique as () => boolean, 'Duplicate Entity IDs'),
  // rawTransform(({ dataset, addIssue, NEVER }): Array<Entity> => {
  //   // console.log('noStackedEntitiesCheck', input)
  //   return dataset.value.filter(ent => ent.id !== 6874)
  // }),
  // rawTransform(({ dataset, addIssue, NEVER }): Array<Entity> => {
  //   // console.log('noStackedEntitiesCheck', input)
  //   return dataset.value.map(ent => {
  //     delete (ent as HasWasBuiltThisTurn).builtThisTurn
  //     delete (ent as HasWaitedThisTurn).waitedThisTurn
  //     delete (ent as HasAttackedThisTurn).attackedThisTurn
  //     return ent
  //   })
  // }),
  rawCheck(({ dataset, addIssue }): void => {
    // console.log('EntityList.rawCheck', input)
    if (dataset.typed) {
      const value: Array<Entity> = dataset.value

      const taxisAndCargoEntsMap = new Map<EntityId, BaseEntity>()
  
      for (let index1 = 0; index1 < value.length; index1++) {
        const ent1 = value[index1];
        if ((ent1 as BaseEntity).cargo) {
          taxisAndCargoEntsMap.set(ent1.id, ent1 as BaseEntity)
        }
        for (let index2 = index1 + 1; index2 < value.length; index2++) {
          const ent2 = value[index2];
          if (ent1.id === ent2.id) {
            addIssue({
              message: `Ent{index:${index1}} and Ent{index2:${index2}} both occupy {id:${ent1.id}}`,
            })
          } else if (ent1.layer_id === ent2.layer_id && samePosition(ent1, ent2)) {
            if ((ent1 as HasTaxiID).taxi_id) {
              // taxi'd ents don't count
              taxisAndCargoEntsMap.set(ent1.id, ent1 as BaseEntity)
            } else if ((ent2 as HasTaxiID).taxi_id) {
              // taxi'd ents don't count
              taxisAndCargoEntsMap.set(ent2.id, ent2 as BaseEntity)
            } else {
              addIssue({
                message: `Ent{id:${ent1.id}} and Ent{id:${ent2.id}} both occupy {x:${ent1.x},y:${ent1.y},z:${ent1.layer_id}}`,
              })
            }
          }
        }
      }
      verifyRelationshipBetweenTransportsAndCargo(taxisAndCargoEntsMap, addIssue)
    }
  })
)

export type EntityList = InferOutput<typeof EntityListSchema>

export const NullableEntityListSchema = nullable(EntityListSchema)

export type NullableEntityList = InferOutput<typeof NullableEntityListSchema>


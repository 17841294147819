import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { ActionType } from '../ActionType'

export const EndRoundActionSchema = object({
  type: literal(ActionType.Game.EndRound),
})

export type EndRoundAction = InferOutput<typeof EndRoundActionSchema>

export function createEndRoundAction(): EndRoundAction {
  return {
    type: ActionType.Game.EndRound,
  }
}

export async function handleEndRoundAction(
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: EndRoundAction
): Promise<void> {
  const { state } = engine
  const { turnPlayerId, players } = state
  const turnPlayerIndex = players.findIndex((p) => p.id === turnPlayerId)
  const nexTurnPlayerId = players[turnPlayerIndex + 1]?.id || players[0].id
  if (turnPlayerId === nexTurnPlayerId) {
    throw new Error('unexpected same playerId')
  }
  state.turnPlayerId = nexTurnPlayerId
}

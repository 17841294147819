import { Component, createEffect, JSX } from 'solid-js'
import { supabaseAxios } from '../axios'
import { random } from '../lib/core/util/math'
import createPathToObjectUrlSignal from '../lib/sb/storage/createPathToObjectUrlSignal'
import { createLoadingSignal } from '../rx/signal/create_loading_signal'
import { Nullable } from '../typescript'
// import { supabase } from '../supabase'

interface Props {
  size: number
  url: Nullable<string>
  onUpload: (event: Event, filePath: string) => void
}

const Avatar: Component<Props> = (props) => {
  const loading = createLoadingSignal()
  const [avatarBlob, setAvatarUrl] = createPathToObjectUrlSignal('avatars', null)

  createEffect(() => {
    if (props.url) setAvatarUrl(props.url)
  })

  const uploadAvatar: JSX.EventHandler<HTMLInputElement, Event> = async (event) => {
    try {
      loading.start()

      const target = event.currentTarget
      if (!target?.files || target.files.length === 0) {
        throw new Error('You must select an image to upload.')
      }

      const file = target.files[0]
      const fileExt = file.name.split('.').pop()
      // TODO filename should be selected by backend
      const fileName = `${random()}.${fileExt}`
      const filePath = `${fileName}`

      // curl 'https://hwylrqkmnilaijnprsat.supabase.co/storage/v1/object/avatars/0.000004195009074114964.jpeg' \
      //   -H 'authority: hwylrqkmnilaijnprsat.supabase.co' \
      //   -H 'accept: */*' \
      //   -H 'accept-language: en-US,en;q=0.9' \
      //   -H 'apikey: REDACTED' \
      //   -H 'authorization: Bearer REDACTED' \
      //   -H 'content-type: multipart/form-data; boundary=----WebKitFormBoundaryXjD5OhxWIupczgsY' \
      //   -H 'origin: http://localhost:3000' \
      //   -H 'referer: http://localhost:3000/' \
      //   -H 'sec-ch-ua: "Chromium";v="113", "Not-A.Brand";v="24"' \
      //   -H 'sec-ch-ua-mobile: ?0' \
      //   -H 'sec-ch-ua-platform: "macOS"' \
      //   -H 'sec-fetch-dest: empty' \
      //   -H 'sec-fetch-mode: cors' \
      //   -H 'sec-fetch-site: cross-site' \
      //   -H 'user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Iron Safari/537.36' \
      //   -H 'x-client-info: supabase-js-web/2.42.7' \
      //   -H 'x-upsert: false' \
      //   --data-raw $'------WebKitFormBoundaryXjD5OhxWIupczgsY\r\nContent-Disposition: form-data; name="cacheControl"\r\n\r\n3600\r\n------WebKitFormBoundaryXjD5OhxWIupczgsY\r\nContent-Disposition: form-data; name=""; filename="a4a3b876-b7b0-48f9-8b99-ef765ff39f98.jpeg"\r\nContent-Type: image/jpeg\r\n\r\n\r\n------WebKitFormBoundaryXjD5OhxWIupczgsY--\r\n' \
      //   --compressed

      const formData = new FormData()
      formData.append('file', file)
      await supabaseAxios.post(`/storage/v1/object/avatars/${filePath}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      // let { error: uploadError } = await supabase.storage.from('avatars').upload(filePath, file)

      // if (uploadError) {
      //   throw uploadError
      // }

      props.onUpload(event, filePath)
    } catch (error) {
      console.error(error)
    } finally {
      loading.end()
    }
  }

  return (
    <div style={{ width: `${props.size}px` }}>
      {avatarBlob() ? (
        <img
          src={avatarBlob()!}
          alt={avatarBlob() ? 'Avatar' : 'No image'}
          class="img-thumbnail"
          style={{ height: `${props.size}px`, width: `${props.size}px` }}
        />
      ) : (
        <div
          class="avatar no-image"
          style={{ height: `${props.size}px`, width: `${props.size}px` }}
        />
      )}
      <div style={{ width: `${props.size}px` }}>
        <label class="btn btn-primary w-100" for="single">
          {loading() ? 'Uploading ...' : 'Upload Avatar'}
        </label>
        <span style={{"display":"none"}}>
          <input
            type="file"
            id="single"
            accept="image/*"
            onChange={uploadAvatar}
            disabled={loading()}
          />
        </span>
      </div>
    </div>
  )
}

export default Avatar

import { VITE_ALLOWS_GUEST_ACCESS_TOKEN } from "./auth_session_key"
import fetchGuestAccessToken from "./fetchGuestAccessToken"
import fetchRefreshedAccessToken from "./fetchRefreshedAccessToken"
import isAccessTokenExpired from "./isAccessTokenExpired"
import { getSessionValue } from "./sessionValue"

export default async function getAccessToken() {
  // console.log('getAccessToken')
  let sessionValue = await getSessionValue()
  // console.log('getAccessToken.sessionValue', sessionValue)
  // console.log('VITE_ALLOWS_GUEST_ACCESS_TOKEN', VITE_ALLOWS_GUEST_ACCESS_TOKEN)
  if (!sessionValue?.access_token && VITE_ALLOWS_GUEST_ACCESS_TOKEN) {
    await fetchGuestAccessToken()
    sessionValue = await getSessionValue()
  }
  // console.log('isAccessTokenExpired()', isAccessTokenExpired())
  if (isAccessTokenExpired()) {
    await fetchRefreshedAccessToken()
    sessionValue = await getSessionValue()
  }
  return sessionValue?.access_token
}
import type { Nullable } from '../../../typescript'
import type { ProfileId } from '../ProfileId.type'
import type { State } from '../state/State.type'
import type { Player } from './Player.type'

export default function findPlayerByProfileId(
  state: Nullable<State>,
  profileUuid: Nullable<ProfileId>
): Player | undefined {
  if (profileUuid && state) {
    return state.players.find((p) => p.profile_id === profileUuid)
  }
}

import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { Player } from '../../../../player/Player.type'
import { PlayerId, PlayerIdSchema } from '../../../../player/PlayerId'
import { PlayerKind } from '../../../../player/PlayerKind'
import resetPlayerSlotToOpen from '../../../../player/resetPlayerSlotToOpen'
import { ActionType } from '../ActionType'

export const ReservePlayerSlotActionSchema = object({
  type: literal(ActionType.Lobby.ReservePlayerSlot),
  player_id: PlayerIdSchema,
})

export type ReservePlayerSlotAction = InferOutput<typeof ReservePlayerSlotActionSchema>

export function createReservePlayerSlotAction(player_id: PlayerId): ReservePlayerSlotAction {
  return {
    type: ActionType.Lobby.ReservePlayerSlot,
    player_id,
  }
}

export async function handleReservePlayerSlotAction(
  engine: Engine,
  action: ReservePlayerSlotAction
): Promise<void> {
  const { player_id } = action
  const is_local = !engine.state.online

  engine.state.players.forEach((player: Player): void => {
    if (player.id === player_id) {
      resetPlayerSlotToOpen(player)
      player.kind = PlayerKind.ReservedSlot
      player.is_local = is_local
    }
  })
}

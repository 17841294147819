import { modifyMutable, produce } from 'solid-js/store'
import { Engine } from './engine/Engine.type'
import { floor } from './util/math'

export function centerPan(engine: Engine): void {
  modifyMutable(
    engine,
    produce((engine: Engine) => {
      const { viewCtx } = engine
      const { width, height } = engine.state

      const { renderWidthPx, renderHeightPx, tile_size, tile_gap_px, canvas_edge_buffer } = viewCtx
      const bufferWidthPx = tile_size * width + tile_gap_px * (width - 1) + 2 * canvas_edge_buffer
      const bufferHeightPx =
        tile_size * height + tile_gap_px * (height - 1) + 2 * canvas_edge_buffer

      viewCtx.pan_x_f = (renderWidthPx - bufferWidthPx) / 2 + canvas_edge_buffer
      viewCtx.pan_y_f = (renderHeightPx - bufferHeightPx) / 2 + canvas_edge_buffer

      viewCtx.pan_x = floor(viewCtx.pan_x_f)
      viewCtx.pan_y = floor(viewCtx.pan_y_f)

      // const dirty = engine.cache[1]
      // dirty(EngineCache.ViewCtx)
    })
  )

  // const len = renderFns.length
  // for (let index = renderFns.length; index--; ) {
  //   renderFns[index]()
  // }
}

import { literal, object, omit, partial, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { assign } from '../../../../util/Object'
import { StateConfigSchema } from '../../../state_config'
import { ActionType } from '../ActionType'

// omiting restrictedUnits because it's complicated sub-object
export const PatchConfigActionDataSchema = partial(omit(StateConfigSchema, ['restrictedUnits']))

export type PatchConfigActionData = InferOutput<typeof PatchConfigActionDataSchema>

export const PatchConfigActionSchema = object({
  type: literal(ActionType.Lobby.PatchConfig),
  data: PatchConfigActionDataSchema,
})

export type PatchConfigAction = InferOutput<typeof PatchConfigActionSchema>

export function createPatchConfigAction(data: PatchConfigActionData): PatchConfigAction {
  return {
    type: ActionType.Lobby.PatchConfig,
    data,
  }
}

export async function handlePatchConfigAction(
  engine: Engine,
  action: PatchConfigAction
): Promise<void> {
  // console.log('handlePatchConfigAction')

  assign(engine.state.config, action.data)
}

import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { ActionType } from '../ActionType'

export const StartRoundActionSchema = object({
  type: literal(ActionType.Game.StartRound),
})

export type StartRoundAction = InferOutput<typeof StartRoundActionSchema>

export function createStartRoundAction(): StartRoundAction {
  return {
    type: ActionType.Game.StartRound,
  }
}

export async function handleStartRoundAction(
  engine: Engine,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: StartRoundAction
): Promise<void> {
  const { state } = engine
  const { turnPlayerId, players } = state
  const turnPlayerIndex = players.findIndex((p) => p.id === turnPlayerId)
  const nexTurnPlayerId = players[turnPlayerIndex + 1]?.id || players[0].id
  if (turnPlayerId === nexTurnPlayerId) {
    throw new Error('unexpected same playerId')
  }
  state.turnPlayerId = nexTurnPlayerId
}

import canUnitDirectAttackEntity from '../../entity/canUnitDirectAttackEntity'
import entityTypeMetaList from '../../entity/entityTypeMetaList.generated'
import hasAtLeastOneWeapon from '../../entity/hasAtLeastOneWeapon'
import type { Entity } from '../../entity/index'
import findAttackableEntity from '../../findAttackableEntity'
import findByIdOrThrow from '../../findByIdOrThrow'
import type { HasMobility } from '../../has_mobility'
import type { HasPlayerId } from '../../player/has_player_id'
import coord from '../coord'
import createLazyAnnexListGrid from '../createLazyAnnexListGrid'
import createLazyCargoListGrid from '../createLazyCargoListGrid'
import createLazyDefenseValueGrid from '../createLazyDefenseValueGrid'
import createLazyEnemyListGrid from '../createLazyEnemyListGrid'
import createLazyImpassValueGrid from '../createLazyImpassValueGrid'
import createLazyMergableValueGrid from '../createLazyMergableValueGrid'
import createLazyMovementCostGrid from '../createLazyMovementCostGrid'
import createLazyOccupantValueGrid from '../createLazyOccupantValueGrid'
import createLazyTaxiListGrid from '../createLazyTaxiListGrid'
import createReadWriteGridXY from '../createReadWriteGridXY'
import { dijkstraDirections } from '../dijkstraDirections'
import type { EntityGridXY } from '../EntityGridXY.type'
import isOrthogonallyAdjacent from '../isOrthogonallyAdjacent'
import type { TilePositionXY } from '../TilePositionXY.type'
import { byTotalCostAsc } from './byTotalCostAsc'
import { getTurnsLastStep } from './getTurnsLastStep'
import { lastStepDefenseOrZero } from './lastStepDefenseOrZero'
import type { PathTurnStepCollection } from './PathTurnStepCollection.type'
import type { PathTurnSteps_Step } from './PathTurnSteps_Step.type'
import type { PathTurnSteps_Turn } from './PathTurnSteps_Turn.type'
import { sumCost } from './sumCost'

// at 100 turns, throw error
const maxTurns = 100

export default function findMostEfficientPathToTileV2(
  grid: EntityGridXY,
  unit: Entity,
  gridWidth: number,
  gridHeight: number,
  targetX: number,
  targetY: number
): PathTurnStepCollection | null {
  // console.log('findMostEfficientPathToTileV2.start', { targetX, targetY })

  const unitEntityTypeMeta = findByIdOrThrow(entityTypeMetaList, unit.etype_id)
  const movePointsPerTurn: number | undefined = (unitEntityTypeMeta.entDefaults as HasMobility).mobility
  const movePointsLeftInFirstTurn: number | undefined = (unit as HasMobility).mobility

  const unitHasWeapon = hasAtLeastOneWeapon(unit)

  if (!movePointsPerTurn) {
    // console.log('findMostEfficientPathToTileV2', '!movePointsPerTurn')
    return null
  }
  // const gridWidth = grid.length
  // const gridHeight = grid[0].length

  const unitPlayerId = (unit as HasPlayerId).player_id

  // start x and y
  const { x, y } = unit
  const unitPosition: TilePositionXY = coord(x, y)
  const targetPosition = coord(targetX, targetY)
  const attackableEntityAtTarget = (unitPlayerId && unitHasWeapon) ? findAttackableEntity(grid[targetX]?.[targetY], unitPlayerId) : null
  // if (targetX===6&&targetY===6) console.log('findMostEfficientPathToTileV2.attackableEntityAtTarget', deepClone(attackableEntityAtTarget))

  const costGrid = createLazyMovementCostGrid(gridWidth, gridHeight, grid, unit)
  const defenseAt = createLazyDefenseValueGrid(gridWidth, gridHeight, grid, unit)
  // const blockedAt = createLazyBlockedValueGrid(gridWidth, gridHeight, grid, unit)
  const impassAt = createLazyImpassValueGrid(gridWidth, gridHeight, grid, unit)
  const occupantAt = createLazyOccupantValueGrid(gridWidth, gridHeight, grid, unit)
  const enemiesAt = createLazyEnemyListGrid(gridWidth, gridHeight, grid, unit)
  const mergableAt = createLazyMergableValueGrid(gridWidth, gridHeight, grid, unit)
  const taxisAt = createLazyTaxiListGrid(gridWidth, gridHeight, grid, unit)
  const cargosAt = createLazyCargoListGrid(gridWidth, gridHeight, grid, unit)
  const annexAt = createLazyAnnexListGrid(gridWidth, gridHeight, grid, unit)

  // occupant: occupantAt(nextX, nextY),
  // enemies: enemiesAt(nextX, nextY),
  // merge: mergableAt(nextX, nextY),
  // loadable: loadableAt(nextX, nextY),
  const [getBest, setBest] = createReadWriteGridXY<PathTurnStepCollection | null>(
    gridWidth,
    gridHeight,
    null
  )
  const pathZero: PathTurnStepCollection = {
    totalCost: 0,
    start: unitPosition,
    end: unitPosition,
    turns: [],
    lastStep: null,
  }
  setBest(unitPosition.x, unitPosition.y, pathZero)

  const queue: Array<PathTurnStepCollection> = [pathZero]

  let iterationsRemaining = 100 * gridWidth * gridHeight
  let previousPath: PathTurnStepCollection | undefined
  while ((previousPath = queue.shift())) {
    if (!(iterationsRemaining-- > 0)) {
      throw new Error('Max iterations exceeded')
    }

    // console.log('while(queue.shift) previousPath', previousPath)
    // const previousPath: Path = queue.shift()
    const {
      start: prevStart,
      end: prevEnd,
      turns: prevTurns,
      totalCost: prevTotalCost,
    } = previousPath
    for (const { dx, dy } of dijkstraDirections) {
      const nextX = prevEnd.x + dx
      const nextY = prevEnd.y + dy
      const nextPosition = coord(nextX, nextY)
      const inBounds = nextX >= 0 && nextY >= 0 && nextX < gridWidth && nextY < gridHeight
      // console.log({ nextX, nextY, inBounds })
      // console.log(
      //   'inBounds',
      //   `${nextX} >= 0 && ${nextY} >= 0 && ${nextX} < ${gridWidth} && ${nextY} < ${gridHeight}`
      // )
      if (inBounds) {
        const isOrthogonallyAdjacentToTarget = isOrthogonallyAdjacent(nextPosition, targetPosition)
        const stepCost = costGrid(nextX, nextY)

        const isLastStepAttack = isOrthogonallyAdjacentToTarget && stepCost===null
          && attackableEntityAtTarget && canUnitDirectAttackEntity(unit, attackableEntityAtTarget)
        // const isLastStepAttack = isOrthogonallyAdjacentToTarget && stepCost===null && canUnitDirectAttackEntity(unit, )

        // if (targetX===6&&targetY===6 && isOrthogonallyAdjacentToTarget && stepCost===null) {
        //   console.log(deepClone({
        //     isOrthogonallyAdjacentToTarget,
        //     stepCost,
        //     // 'stepCost===null': stepCost===null,
        //     attackableEntityAtTarget,
        //     'canUnitDirectAttackEntity(unit, attackableEntityAtTarget)': attackableEntityAtTarget && canUnitDirectAttackEntity(unit, attackableEntityAtTarget),
        //   }))
        //   console.log('isLastStepAttack', isLastStepAttack)
        // }
        const newStep : PathTurnSteps_Step = {
          x: nextX,
          y: nextY,
          cost: stepCost || 0,
          defense: defenseAt(nextX, nextY),
          impass: impassAt(nextX, nextY),
          occupant: occupantAt(nextX, nextY),
          enemies: enemiesAt(nextX, nextY),
          merge: mergableAt(nextX, nextY),
          taxis: taxisAt(nextX, nextY),
          pickup: cargosAt(nextX, nextY),
          annex: annexAt(nextX, nextY),
        }
        // console.log({ nextX, nextY, stepCost })
        // if stepCost is null, the next tile is impassible
        if (isLastStepAttack) {
          const nextTotalCost = prevTotalCost
          const nextDefense = defenseAt(nextX, nextY)
          const currentBestPath = getBest(nextX, nextY)
          // console.log(currentBestPath?.start, 'lastStepDefense(currentBestPath)', lastStepDefense(currentBestPath), 'nextDefense', nextDefense, {nextX, nextY})
          // console.log({ nextTotalCost, currentBestTotalCost: currentBestPath?.totalCost })
          // make sure this newPath is actually good
          // before blowing out the gc
          const isNewBest = !(currentBestPath && (nextDefense as number) >= (lastStepDefenseOrZero(currentBestPath)))
          // console.log(
          //   JSON.stringify({
          //     isNewBest,
          //     hasCurrentBest: !!currentBestPath,
          //     'nextTotalCost >= currentBestPath.totalCost':
          //       nextTotalCost >= (currentBestPath?.totalCost || 0),
          //   })
          // )
          if (isNewBest) {
            const newPath: PathTurnStepCollection = {
              totalCost: nextTotalCost,
              start: prevStart,
              end: { x: nextX, y: nextY },
              turns: [...prevTurns],
              // blocked: blockedAt(nextX, nextY),
              lastStep: previousPath.lastStep,
              targetStep: newStep,
            }
            // console.log('newPath(setBest+queue.push)', JSON.stringify(newPath).replaceAll('"', ''))
            setBest(nextX, nextY, newPath)
            // attacks are terminating, so no additional queueing from this branch
          }
        } else if (stepCost) {
          const nextTotalCost = prevTotalCost + stepCost
          const currentBestPath = getBest(nextX, nextY)
          // console.log({ nextTotalCost, currentBestTotalCost: currentBestPath?.totalCost })
          // make sure this newPath is actually good
          // before blowing out the gc
          const isNewBest = !(currentBestPath && nextTotalCost >= currentBestPath.totalCost)
          // console.log(
          //   JSON.stringify({
          //     isNewBest,
          //     hasCurrentBest: !!currentBestPath,
          //     'nextTotalCost >= currentBestPath.totalCost':
          //       nextTotalCost >= (currentBestPath?.totalCost || 0),
          //   })
          // )
          if (isNewBest) {
            const lastTurnIndex = prevTurns.length - 1
            const prevLastTurn = prevTurns[lastTurnIndex]
            let turns: Array<PathTurnSteps_Turn>
            if (prevLastTurn) {
              // console.log('branch: prevLastTurn')
              const turnCost = prevLastTurn.steps.reduce(sumCost, 0)
              if (turnCost >= prevLastTurn.mobility) {
                // console.log('branch: prevLastTurn: turnCost >= movePointsPerTurn')
                const newTurn = {
                  steps: [newStep],
                  turn: prevLastTurn.turn + 1,
                  mobility: movePointsPerTurn,
                }
                turns = [...prevTurns, newTurn]
              } else {
                // console.log('branch: prevLastTurn: !(turnCost >= movePointsPerTurn)')
                const newTurn = {
                  ...prevLastTurn,
                  steps: [...prevLastTurn.steps, newStep],
                }
                turns = prevTurns.concat()
                turns[lastTurnIndex] = newTurn
              }
            } else if (movePointsLeftInFirstTurn > 0) {
              // console.log('branch: !prevLastTurn')
              const newTurn = {
                steps: [newStep],
                turn: 1,
                mobility: movePointsLeftInFirstTurn,
              }
              turns = [newTurn]
            } else {
              // console.log('branch: !prevLastTurn')
              const emptyTurn = {
                steps: [],
                turn: 1,
                mobility: 0,
              }
              const newTurn = {
                steps: [newStep],
                turn: 2,
                mobility: movePointsPerTurn,
              }
              turns = [emptyTurn, newTurn]
            }
            if (turns.length > maxTurns) {
              throw new Error('maxTurns (100) exceeded')
            }
            const newPath: PathTurnStepCollection = {
              totalCost: nextTotalCost,
              start: prevStart,
              end: { x: nextX, y: nextY },
              turns,
              // blocked: blockedAt(nextX, nextY),
              lastStep: getTurnsLastStep(turns),
            }
            // console.log('newPath(setBest+queue.push)', JSON.stringify(newPath).replaceAll('"', ''))
            setBest(nextX, nextY, newPath)
            queue.push(newPath)
            // explore shorter paths first
            // for efficiency
            queue.sort(byTotalCostAsc)
          }
        }
      }
    }
  }

  // console.log(`getBest(${targetX}, ${targetY})`, deepClone(getBest(targetX, targetY)))
  return getBest(targetX, targetY)
}

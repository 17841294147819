import { literal, object, parse, type InferOutput } from 'valibot'
import isNil from '../../../../../ldsh/isNil'
import { Engine } from '../../../../engine/Engine.type'
import { FactoryEntitySchema, type FactoryEntity } from '../../../../entity/EntityType/Factory'
import type { EntityUnionForFactory } from '../../../../entity/EntityUnionForFactory.type'
import { EntityIdSchema } from '../../../../entity/id.type'
import findById from '../../../../findById'
import findByIdOrThrow from '../../../../findByIdOrThrow'
import { samePosition } from '../../../../tile_position_xy/samePosition'
import type { HasWasBuiltThisTurn } from '../../../../WasBuiltThisTurn'
import { ActionType } from '../ActionType'
import entityTypeMetaList from '../../../../entity/entityTypeMetaList.generated'

export const CancelFactoryOrderActionSchema = object({
  type: literal(ActionType.Game.CancelFactoryOrder),
  factory_id: EntityIdSchema,
})

export type CancelFactoryOrderAction = InferOutput<typeof CancelFactoryOrderActionSchema>

export function createCancelFactoryOrderAction(
  factoryEntity: FactoryEntity
): CancelFactoryOrderAction {
  return {
    type: ActionType.Game.CancelFactoryOrder,
    factory_id: factoryEntity.id,
  }
}

export async function handleCancelFactoryOrderAction(
  engine: Engine,
  action: CancelFactoryOrderAction
): Promise<void> {
  const { factory_id } = action
  const { state } = engine
  const { ents, players, turnPlayerId } = state

  const factoryEntity = parse(
    FactoryEntitySchema,
    findById(ents, factory_id)
  )
  const { player_id } = factoryEntity
  if (player_id !== turnPlayerId) {
    throw new Error('Unexpected player_id !== turnPlayerId')
  }
  const player = findByIdOrThrow(players, player_id)

  const entToCancel = ents.find((entity): entity is EntityUnionForFactory => {
    return ((entity as HasWasBuiltThisTurn).builtThisTurn as boolean) && samePosition(factoryEntity, entity)
  })

  if (!entToCancel) {
    // throw new Error('Unexpected !entToCancel')
    // no action necessary
    return
  }

  const entityTypeMeta = findByIdOrThrow(entityTypeMetaList, entToCancel.etype_id)
  const refundAmount = entityTypeMeta.price
  // rule out undefined
  if (isNil(refundAmount)) {
    throw new Error('No Refunds')
  }

  state.ents = ents.filter((ent1) => ent1.id !== entToCancel.id)
  player.money += refundAmount
}

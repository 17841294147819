import { FaSolidArrowRotateLeft, FaSolidTrashCan } from 'solid-icons/fa'
import { createMemo } from 'solid-js'
import type { StateOnlineId } from '../../lib/core/state/StateOnlineId.type'
import { createSessionSignal } from '../../rx/shared/session/createSessionSignal'
import { LoadingSignal, createLoadingSignal } from '../../rx/signal/create_loading_signal'
import { AlertBagStore, createAlertBagStore } from '../../rx/store/create_alert_bag_store'
import { Nullable } from '../../typescript'
import { GetAlertBagSuccessMessageFunction, GetItemValueFunction, OnChangeFunction, onClickMutateTimestampFunctionFactory } from './onClickMutateTimestampFunctionFactory'
import LoadingIcon from '../LoadingIcon'

interface Deletable {
  id: StateOnlineId
  deleted_at: string | null,
}

interface Props {
  disabled: Nullable<boolean>,
  item: Deletable,
  noun: string,
  // ariaLabel: string,
  onChange: OnChangeFunction,
  ab: AlertBagStore,
}

const getItemValue: GetItemValueFunction = function (item: Deletable) {
  return item?.deleted_at
} as GetItemValueFunction

const getAlertBagSuccessMessage: GetAlertBagSuccessMessageFunction = function (newValue: string | null, noun: string): Nullable<string> {
  return newValue ? `${noun} deleted.` : `${noun} restored.`
} as GetAlertBagSuccessMessageFunction

const timestampKey = 'deleted_at'

const DeleteButton = (props: Props) => {
  const loading: LoadingSignal = createLoadingSignal()
  // eslint-disable-next-line solid/reactivity
  const ab = props.ab || createAlertBagStore()
  const session = createSessionSignal()[0]

  const ariaLabel = createMemo(() => {
    return props.item && (getItemValue(props.item) ? `Undelete ${props.noun}` : `Delete ${props.noun}`)
  })

  return (<button
    class="btn btn-danger btn-sm"
    disabled={!props.item?.id || props.disabled || loading()}
    aria-label={ariaLabel()}
    title={ariaLabel()}
    onClick={onClickMutateTimestampFunctionFactory(
      props, timestampKey, ab, session, loading,
      getAlertBagSuccessMessage, getItemValue)}
  >
    {(loading() || !props.item)
      ? <LoadingIcon />
      : (getItemValue(props.item)
        ? <FaSolidArrowRotateLeft />
        : <FaSolidTrashCan />)}
  </button>)
}

export default DeleteButton



import type { Weapon } from './Weapon.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

export default function createAntiAirCannonWeapon(str: number): Weapon {
  return {
    wt_id: WeaponTypeId.AntiAirCannon,
    str,
    ammo: 1,
    ca: true,
  }
}

import { BioSchema } from '@sg/shared/src/valibot/schema/Bio.type'
import { DisplayNameSchema } from '@sg/shared/src/valibot/schema/DisplayName.type'
import { InferOutput, nullable, object, optional } from 'valibot'
import { StateMapDiskSchema } from './state_map_disk.enum'
import { StateOnlineIdSchema } from './StateOnlineId.type'

export const StateMapSchema = object({
  id: optional(nullable(StateOnlineIdSchema)),
  dname: optional(nullable(DisplayNameSchema)),
  bio: optional(nullable(BioSchema)),
  disk: StateMapDiskSchema,
})

export type StateMap = InferOutput<typeof StateMapSchema>

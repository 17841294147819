import { Component, createMemo, Match, Show, Switch, useContext, type Accessor } from 'solid-js'
import createDraftMoveAttackEstimate from '../../lib/core/draft_move/createDraftMoveAttackEstimate'
import type { DraftMove_AttackEstimate } from '../../lib/core/draft_move/DraftMove_AttackEstimate.type'
import type { Engine } from '../../lib/core/engine/Engine.type'
import getEntitiesAtPosition from '../../lib/core/entity/getEntitiesAtPosition'
import type { Entity } from '../../lib/core/entity/index'
import type { HasHP } from '../../lib/core/has_hp'
import formatGameMoney from '../../lib/core/player/formatGameMoney'
import type { HasPlayerId } from '../../lib/core/player/has_player_id'
import coord from '../../lib/core/tile_position_xy/coord'
import { samePosition } from '../../lib/core/tile_position_xy/samePosition'
import toFixed1 from '../../lib/core/util/toFixed1'
import type { Nullable } from '../../typescript'
import EngineContext from '../EngineContext'
import PositionStackStats from './PositionStackStats'

const AttackPositionCornerWidget: Component = () => {
  const engine : Engine = useContext(EngineContext)
  // const [isExpanded, setIsExpanded] = createSignal<boolean>(false)
  // let prevAttackPosition : Nullable<DraftMove['attackPosition']> = null
  // createEffect(() => {
  //   const nextAttackPosition = engine.draftMove?.attackPosition
  //   if (nextAttackPosition && !samePosition(nextAttackPosition, prevAttackPosition)) {
  //     setIsExpanded(false)
  //   }
  //   prevAttackPosition = nextAttackPosition
  // })
  // createEffect(() => {
  //   const draftMove = engine.draftMove
  //   if (draftMove) {
  //     const { attackPosition } = draftMove
  //     if (!attackPosition) {
  //       // draftMove.attackPosition = coord(6,6)
  //     }
  //   }
  // })

  const attackPositionTargetEntsStack = createMemo(() => {
    const attackPosition = engine.draftMove?.attackPosition || engine.hoveredPosition
    if (attackPosition) {
      return getEntitiesAtPosition(engine.state.ents, attackPosition)
    }
    return []
  })

  const attackPositionUnitEntsStack = createMemo(() => {
    const { draftMove } = engine
    if (draftMove) {
      const { unit, destPosition } = draftMove
      const attackPosition = destPosition || coord(unit.x, unit.y)
      if (attackPosition) {
        return getEntitiesAtPosition(engine.state.ents, attackPosition)
      }
    }
    return []
  })

  const estimate = createMemo<Nullable<DraftMove_AttackEstimate>>(() => {
    const { draftMove } = engine
    if (draftMove) {
      // console.log('estimate.draftMove', deepClone(draftMove))
      const { unit, destPosition } = draftMove
      const unitPosition = destPosition || coord(unit.x, unit.y)
      const attackPosition = engine.draftMove?.attackPosition || engine.hoveredPosition
      if (attackPosition) {
        const { player_id: unitPlayerId } = unit as Entity & HasPlayerId

        const targetEnt = attackPositionTargetEntsStack().find((entity : Entity): entity is Entity & HasHP => {
          if (samePosition(entity, attackPosition)) {
            if (unitPlayerId && unitPlayerId !== (entity as HasPlayerId).player_id) {
              if ('hp' in entity) {
                return true
              }
            }
          }
          return false
        })

        if (targetEnt) {
          const estimate = createDraftMoveAttackEstimate(engine, unit, unitPosition, targetEnt)
          return estimate
        }
      }
    }
  })

  return (
    <Show when={estimate()} children={(estimate : Accessor<DraftMove_AttackEstimate>) => {
      return (
        <div class="cw cw-atkps-est float-end m-2">
          <div class="text-center">
            <Switch>
              <Match when={(estimate().unitWeaponEstimate?.distance as number) === 1}>
                <span class="badge text-bg-success">Direct Attack</span>
              </Match>
              <Match when={(estimate().unitWeaponEstimate?.distance as number) > 1}>
                <span class="badge text-bg-success">Ranged Attack</span>
              </Match>
              <Match when={(estimate().targetWeaponEstimate?.distance as number) >= 1}>
                <span class="badge text-bg-success">Defense</span>
              </Match>
              <Match when={true}>
                <span class="badge text-bg-error">Error</span>
              </Match>
            </Switch>
          </div>
          <table>
            <tbody>
              <tr>
                <td class="text-end">Approx DMG</td>
                <td class="text-end text-success">{toFixed1(estimate().targetDmg)}</td>
                <td class="text-end text-danger">{toFixed1(estimate().unitDmg)}</td>
                <td class="text-start">Approx DMG</td>
              </tr>
              <tr>
                <td class="text-end">Approx CBA</td>
                <td class="text-end text-success">{formatGameMoney(estimate().targetCba)}</td>
                <td class="text-end text-danger">{formatGameMoney(estimate().unitCba)}</td>
                <td class="text-start">Approx CBA</td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col">
              <PositionStackStats ents={attackPositionUnitEntsStack()} x={false} />
            </div>
            <div class="col">
              <PositionStackStats ents={attackPositionTargetEntsStack()} x={false} />
            </div>
          </div>
        </div>
      )
    }} />
  )
}

export default AttackPositionCornerWidget

import { FaSolidUser } from 'solid-icons/fa'
import { Component, createEffect } from 'solid-js'
import createPathToObjectUrlSignal from '../lib/sb/storage/createPathToObjectUrlSignal'
import { Nullable } from '../typescript'

interface Props {
  size: number,
  url: Nullable<string>,
  // loading: Resource<any>,
  dname: Nullable<string>,
}

const ShowAvatar: Component<Props> = (props) => {
  const [avatarBlob, setAvatarUrl] = createPathToObjectUrlSignal('avatars', null)

  createEffect(() => {
    if (props.url) setAvatarUrl(props.url)
  })

  return (
    <div class="avatar" style={{ width: `${props.size}px` }}>
      {avatarBlob() ? (
        <img
          src={avatarBlob()!}
          alt={`${props.dname || 'unknown'}'s Avatar`}
          class="img-thumbnail"
          style={{ height: `${props.size}px`, width: `${props.size}px` }}
        />
      ) : (
        <div
          class="avatar no-image"
        >
          <FaSolidUser
            class="m-1 p-2"
            style={{ height: `${props.size}px`, width: `${props.size}px` }}
          />
        </div>
      )}
      {/* <Loader resource={props.loading} /> */}
    </div>
  )
}

export default ShowAvatar

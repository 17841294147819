import type { Nullable } from '../../../typescript'
import type { EntityId } from './id.type'
import type { Entity } from './index'

export default function findEntityById(ents: Array<Entity>, id: Nullable<EntityId>): Entity | null {
  for(let index = ents.length; index--;) {
    const item = ents[index]
    if (item.id === id) {
      return item
    }
  }
  return null
}

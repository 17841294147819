import { createEffect, type Accessor } from 'solid-js'
import { modifyMutable, produce } from 'solid-js/store'
import type { Engine } from '../../lib/core/engine/Engine.type'
import setAuthPlayerIdFromProfileId from '../../lib/core/engine/setAuthPlayerIdFromProfileId'
import type { AuthProfile } from '../shared/profile/auth_profile.type'
import type { Nullable } from '../../typescript'

export default function createSetEngineAuthPlayerIdFromAuthProfileSignalEffect(
  engine: Engine,
  authProfile: Accessor<Nullable<AuthProfile>>
) {
  createEffect(() => {
    modifyMutable(
      engine,
      produce((engine) => {
        setAuthPlayerIdFromProfileId(engine, authProfile()?.id)
      })
    )
  })
}

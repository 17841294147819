import { A } from '@solidjs/router'
import { Component, createMemo } from 'solid-js'
import createAuthProfileSignal from '../rx/shared/profile/createAuthProfileSignal'

const IndexPage: Component = () => {

  const [authProfile] = createAuthProfileSignal()

  const gamesPath = createMemo(() => {
    const authId = authProfile()?.id
    if (authId) {
      return `/games?player_profile_uuid=${authId}`
    }
    return "/games"
  })

  return (
    <div class="container w-24-rem">
      <h2>Simple Tactics</h2>
      <h2>
        <A href={"/lobby/create"}>New Skirmish Game</A>
      </h2>
      <h2>
        <A href={gamesPath()}>Games</A>
      </h2>
      <h2>
        <A href={"/maps"}>Maps</A>
      </h2>
      <h2>
        <A href={"/codex"}>Codex</A>
      </h2>
    </div>
  )
}

export default IndexPage

import type { Weapon } from './Weapon.type'
import { WeaponTypeId } from './WeaponTypeId.enum'

export default function createAttackCopterMissilesWeapon(str: number): Weapon {
  return {
    wt_id: WeaponTypeId.Missile,
    str,
    ammo: 1,
    ca: true,
  }
}

import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { Player } from '../../../../player/Player.type'
import { PlayerId, PlayerIdSchema } from '../../../../player/PlayerId'
import { PlayerKind } from '../../../../player/PlayerKind'
import resetPlayerSlotToOpen from '../../../../player/resetPlayerSlotToOpen'
import { ActionType } from '../ActionType'

// type BotId = Nullable<AuthProfile['id']>

export const SetBotPlayerSlotActionSchema = object({
  type: literal(ActionType.Lobby.SetBotPlayerSlot),
  // if bot_id is null, then it's a built-in bot
  // bot_id: BotId
  player_id: PlayerIdSchema,
})

export type SetBotPlayerSlotAction = InferOutput<typeof SetBotPlayerSlotActionSchema>

export function createSetBotPlayerSlotAction(
  // bot_id: BotId,
  player_id: PlayerId
): SetBotPlayerSlotAction {
  return {
    type: ActionType.Lobby.SetBotPlayerSlot,
    // bot_id,
    player_id,
  }
}

export async function handleSetBotPlayerSlotAction(
  engine: Engine,
  action: SetBotPlayerSlotAction
): Promise<void> {
  const { player_id } = action
  const is_local = !engine.state.online

  engine.state.players.forEach((player: Player): void => {
    if (player.id === player_id) {
      resetPlayerSlotToOpen(player)
      player.kind = PlayerKind.Bot
      player.is_local = is_local
      player.bot_id = 'sg-bot-1'
    }
  })
}

import {
  IndexMapQuerySchema,
  MapRowCollection,
  type IndexMapQuery,
} from '@sg/backend/src/api/st1/map/handleIndexMap'
import { createResource, type Accessor } from 'solid-js'
import { parse, safeParse } from 'valibot'
import { backendAxios } from '../../axios'
import { StateSchema } from '../../lib/core/state/State.type'

async function fetchMapCollectionByQuery(inputParams: IndexMapQuery): Promise<MapRowCollection> {
  // console.log('fetchMapCollectionByQuery', inputParams)
  const result = safeParse(IndexMapQuerySchema, inputParams)
  if (result.success) {
    const params = result.output
    const response = await backendAxios.get(`/st1/map`, { params })
    const collection = response.data as MapRowCollection
    collection.forEach((row): void => {
      // validate row
      row.data = parse(StateSchema, row.data)
    })
    return collection as MapRowCollection
  }
  return []
}

export default function createMapCollectionResource(
  getInputQuery: Accessor<Partial<IndexMapQuery>>
) {
  const resource = createResource(getInputQuery, fetchMapCollectionByQuery)
  return resource
}

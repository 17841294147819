import type { MapRowValue } from '@sg/backend/src/lib/db/getMapOr404'
import { A, useNavigate, useSearchParams } from '@solidjs/router'
import { FaSolidPencil } from 'solid-icons/fa'
import { createEffect, createMemo, Show, Suspense } from 'solid-js'
import AlertBag from '../../components/AlertBag'
import DeleteButton from '../../components/Btn/DeleteButton'
import Loader from '../../components/Loader'
import MapShowCanvas from '../../components/MapShowCanvas'
import MapShowTable from '../../components/MapShowTable'
import { StateType } from '../../lib/core/state/state_type.enum'
import createRedirectIfWrongStateTypeEffect from '../../rx/effect/createRedirectIfWrongStateTypeEffect'
import createMapRowResource from '../../rx/resource/createMapRowResource'
import { createLoadingSignal, registerLoadingResource } from '../../rx/signal/create_loading_signal'
import { createAlertBagStore, registerAlertBagResource, setAlertBagWarning } from '../../rx/store/create_alert_bag_store'

type MapShowPageSearchParams = {
  map_id: string
}

const MapShowPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams<MapShowPageSearchParams>()
  // if (!searchParams.map_id) {
  //   navigate('/maps')
  // }

  const loading = createLoadingSignal()
  const ab = createAlertBagStore()

  const stateId = createMemo<number>((): number => parseInt(searchParams.map_id as string))

  // eslint-disable-next-line
  const mapRowResource = createMapRowResource(stateId)
  const [mapRow] = mapRowResource
  createEffect(() => {
    const id = stateId()
    if (!id) {
      navigate('/maps')
    }
  })
  createRedirectIfWrongStateTypeEffect(StateType.Map, () => mapRow()?.data?.type, ()=>mapRow()?.data?.online?.id)

  registerLoadingResource(loading, mapRowResource)
  registerAlertBagResource(ab, mapRowResource)

  createEffect(() => {
    const deleted_at = mapRow()?.deleted_at
    if (deleted_at) {
      setAlertBagWarning(ab, 'This map has been marked for deletion and will soon be removed by an automated garbage collection process.')
    } else {
      setAlertBagWarning(ab, null)
    }
  })

  return (
    <div>
      <Loader signal={loading} />
      <AlertBag store={ab} />
      <Suspense fallback={<div class="text-muted">Loading...</div>}>
        <Show when={mapRow()?.data}>{(state) =>
          <>
            <h2>
              {(state().map.dname || state().map.id)}
              {' '}
              <A class="btn btn-primary btn-sm"
                href={`/map/edit?map_id=${stateId()}`}
                aria-label="edit map">
                <FaSolidPencil />
              </A>
              {' '}
              <DeleteButton
                noun='Map'
                disabled={loading()}
                ab={ab}
                item={mapRow() as NonNullable<ReturnType<typeof mapRow>>}
                onChange={(mutateFn) => {
                  // console.log('onChange', 'mutateFn', mutateFn)
                  // mapItemResource[1].refetch()
                  // @ts-expect-error resources have a weird nilable thing
                  mutate(mutateFn)
                }}
              />
            </h2>
            <MapShowTable row={mapRow() as MapRowValue} />
            <MapShowCanvas state={state()} width={512} height={512} />
          </>
        }</Show>
      </Suspense>
    </div>
  )
}

export default MapShowPage
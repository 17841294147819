import { useBeforeLeave } from '@solidjs/router'
import { Modal } from 'solid-bootstrap'
import { Component, createEffect, createSignal, useContext } from 'solid-js'
import type { Engine } from '../../lib/core/engine/Engine.type'
import type { Player } from '../../lib/core/player/Player.type'
import { PlayerTurnStatus } from '../../lib/core/player/PlayerTurnStatus'
import { createStartTurnAction } from '../../lib/core/state/flux/action/Game/StartTurnAction'
import dispatchClient from '../../lib/core/state/flux/dispatchClient'
import withBusy from '../../lib/withBusy'
import { createAuthPlayerMemo } from '../../rx/signal/createAuthPlayerSignal'
import EngineContext from '../EngineContext'

const YourTurnModalCornerWidget: Component = () => {
  const engine : Engine = useContext(EngineContext)
  const authPlayer = createAuthPlayerMemo(engine)

  createEffect(() => {
    if (authPlayer()?.turn_status === PlayerTurnStatus.Pending) {
      setIsYourTurnModalOpen(true)
    }
  })
  const [isYourTurnModalOpen, setIsYourTurnModalOpen] = createSignal<boolean>(false)
  // const handleClickToggleIsYourTurnModalOpen = (event: Event) => {
  //   setIsYourTurnModalOpen(!isYourTurnModalOpen())
  //   preventDefault(event)
  // }
  const closeYourTurnModal = () => setIsYourTurnModalOpen(false)

  useBeforeLeave(closeYourTurnModal)

  const [isBusy, setIsBusy] = createSignal<boolean>(false)

  // eslint-disable-next-line solid/reactivity
  const onStartTurn = withBusy(isBusy, setIsBusy, async () => {
    console.log('withBusy(onStartTurn)')
    await dispatchClient(engine, createStartTurnAction((authPlayer() as Player).id))
    closeYourTurnModal()
  })

  return (
    <Modal show={isYourTurnModalOpen()} onHide={onStartTurn} centered>
      <Modal.Body>
        <h2 class="text-center p-1">
          <span class="text-monospace">{authPlayer()?.dname}</span>'s Turn
        </h2>
        <div class="text-center p-2">
          <button class="btn btn-success" onClick={onStartTurn}>Start Turn</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default YourTurnModalCornerWidget

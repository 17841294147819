import { FaSolidUser } from 'solid-icons/fa'
import { Component, For, JSX, createMemo, useContext } from "solid-js"
import { Engine } from '../../lib/core/engine/Engine.type'
import findById from '../../lib/core/findById'
import createSelectedPlayerMemo from '../../lib/core/player/createSelectedPlayerMemo'
import { Player } from '../../lib/core/player/Player.type'
import { Nullable } from '../../typescript'
import EngineContext from '../EngineContext'

const SelectPlayerInput: Component = () => {
  const engine: Engine = useContext(EngineContext)

  const selectedPlayer = createSelectedPlayerMemo(engine)

  function setPlayer(player: Nullable<Player>): void {
    // console.log('SelectPlayerInput.setPlayer()', player?.dname || null)
    engine.selectedPlayerId = player?.id || null
  }

  const iconSpanStyles = createMemo<JSX.CSSProperties | undefined>(() => {
    const player = selectedPlayer()
    // console.log('createMemo.iconSpanStyles.player', player?.dname || null)
    if (player && player.color1) {
      return {
        color: player.color1 || void 0,
        'border-color': `${player.color2}`,
      }
    }
    return {}
  })
  // createEffect(() => {
  //   console.log('iconSpanStyles', iconSpanStyles())
  // })

  return <div class="input-group">
    <span class="input-group-text" style={iconSpanStyles()}>
      <FaSolidUser />
    </span>
    <select name="selected_player_id" class="form-select" aria-label="Select Current Player"
      onChange={(event) => {
        // console.log(event, event.currentTarget.value)
        const player_id = parseInt(event.currentTarget.value)
        const player = findById(engine.state.players, player_id)
        setPlayer(player)
      }}
    >
      <option selected={!engine.selectedPlayerId}>None (neutral)</option>
      <For each={engine.state.players}>{(player: Player) => {
        return <option selected={engine.selectedPlayerId === player.id} value={player.id}>{player.dname}</option>
      }}</For>
    </select>
  </div>
}

export default SelectPlayerInput

import { number, object, optional, type InferOutput } from 'valibot'
import { AmmoSchema } from '../../ammo.type'
import { OptionalLiteralTrueSchema } from '../../LiteralTrue'
import { WeaponStrengthSchema } from './WeaponStrength.type'
import { WeaponTypeIdSchema } from './WeaponTypeId.enum'

export const WeaponSchema = object({
  wt_id: WeaponTypeIdSchema,
  // ammo consumed per use
  ammo: optional(AmmoSchema),
  str: optional(WeaponStrengthSchema),
  minRange: optional(number()),
  maxRange: optional(number()),
  // counter attacks
  ca: OptionalLiteralTrueSchema,
  // surface to air only
  airOnly: OptionalLiteralTrueSchema,
})

export type Weapon = InferOutput<typeof WeaponSchema>

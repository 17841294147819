import { FaSolidRightFromBracket } from "solid-icons/fa"
import { createAlertBagStore, setAlertBagError } from '../rx/store/create_alert_bag_store'
import AlertBag from './AlertBag'
import clearSessionToken from "../rx/shared/session/clearSessionToken"

const SignOutButton = () => {
  const ab = createAlertBagStore()
  const handleClick = () => {
    try {
      setAlertBagError(ab, null)
      // await supabase.auth.signOut()
      // await clearAuthTokens()
      clearSessionToken()
    } catch (error) {
      console.error('Error signing out:', error)
      setAlertBagError(ab, error as Error)
    }
  }

  return (
    <button class="btn btn-danger btn-sm" onClick={handleClick}>
      Sign Out <FaSolidRightFromBracket />
      <AlertBag store={ab} />
    </button>
  )
}

export default SignOutButton
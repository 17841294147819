import { ProfileIdSchema } from '@sg/st1/src/lib/core/ProfileId.type'
import type { State } from '@sg/st1/src/lib/core/state/State.type'
import { StateType } from '@sg/st1/src/lib/core/state/state_type.enum'
import { Context } from 'hono'
import { object, optional, parse, type InferOutput } from 'valibot'
import authOr401 from '../../../lib/auth/authOr401'
import createAdminSupabaseClient from '../../../lib/supabase/createAdminSupabaseClient'

export const IndexMapQuerySchema = object({
  owner_profile_uuid: optional(ProfileIdSchema),
  // deleted: optional(nullable(picklist([0, 1]))),
  // sortBy: optional(nullable(ProfileIdSchema)),
})

export type IndexMapQuery = InferOutput<typeof IndexMapQuerySchema>

const SelectColumns = `
id,
data,
created_at,
updated_at,
published_at,
deleted_at,
creator:profiles!st1_states_creator_profile_uuid_profile_fkey(id, dname, handle, avatar_url),
owner:profiles!st1_states_owner_profile_uuid_profile_fkey(id, dname, handle, avatar_url)
`

async function dummyFetch(c: Context, newState: State) {
  const list = (await createAdminSupabaseClient(c).from('st1_states').select(SelectColumns)).data
  if (list) {
    return list.map((v) => ({
      ...v,
      data: newState,
    }))
  }
}

export type MapRowCollection = NonNullable<Awaited<ReturnType<typeof dummyFetch>>>

export default async function handleIndexMap(c: Context) {
  console.log('handleIndexMap')
  const auth = await authOr401(c)

  const input: IndexMapQuery = parse(IndexMapQuerySchema, c.req.query())

  // const supabaseUser = createUserSupabaseClient(c)
  const supabaseAdmin = createAdminSupabaseClient(c)

  const query = supabaseAdmin.from('st1_states').select(SelectColumns)

  if (input.owner_profile_uuid) {
    query.eq('owner_profile_uuid', input.owner_profile_uuid)
  }

  query.eq('state_type', StateType.Map)

  const isSearchingOwn = auth.sub && auth.sub === input.owner_profile_uuid

  if (!isSearchingOwn) {
    query.not('published_at', 'is', null)
  }

  query.is('deleted_at', null)
  // whereSoftDeletedFromInput(query, input.deleted)

  query.order('updated_at', { ascending: false })

  const stateResponse = await query

  const { error: stateRowError, data } = stateResponse

  if (stateRowError) {
    throw stateRowError
  }

  const stateRowData = data as MapRowCollection

  // const state = parse(StateSchema, stateRowData.state)
  // if (state.type === StateType.Game) {
  //   if (state.config.fow) {
  //     const auth = await authOr401(c)
  //     // console.log('auth', auth)
  //     const userId: string = auth.sub as string
  //     console.log('userId', userId)
  //     if (!userId) {
  //       throw new Error('User not found')
  //     }
  //     throw new Error('Not Implemented: FoW needs redaction logic')
  //   }
  // }

  return c.json(stateRowData)
}

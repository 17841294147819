import { enum_ } from 'valibot'

export enum PlayerKind {
  /**
   * An open slot, any player can join.
   */
  OpenSlot = 2,
  /**
   * A Reserved slot, known by their invite_id
   */
  ReservedSlot = 3,
  /**
   * A Human player, known by their profile_id
   */
  Human = 4,
  /**
   * A Bot player, known by their profile_id
   */
  Bot = 5,
  /**
   * A Spectator player, known by their profile_id
   */
  Spectator = 6,
}

export const PlayerKindSchema = enum_(PlayerKind, 'Invalid Player Kind')

import { A } from '@solidjs/router'
import { For, createMemo } from 'solid-js'
import { createMutable } from 'solid-js/store'
import EngineContext from '../../../components/EngineContext'
import SingleSpriteIcon from '../../../components/MapEditor/SingleSpriteIcon'
import NA from '../../../components/Util/na'
import bindEngineToComponent from '../../../lib/canvas/bind_engine_to_component'
import createEngineForUI from '../../../lib/core/engine/createEngineForUI'
import { Engine } from '../../../lib/core/engine/Engine.type'
import createSampleEntity from '../../../lib/core/entity/create_sample_entity'
import entityTypeMetaList from '../../../lib/core/entity/entityTypeMetaList.generated'
import getMoveCostPair from '../../../lib/core/entity/getMoveCostPair'
import { MoveTypeId } from '../../../lib/core/entity/move_type_id.enum'
import { EntityTypeMeta, isSpriteTerrain } from '../../../lib/core/EntityTypeMeta'
import { toSlug } from '../../../lib/slug'

const CodexTerrainIndexPage = () => {

  // console.log('CodexTerrainIndexPage')

  const entityTypes = entityTypeMetaList.filter((sprite: EntityTypeMeta) => sprite && isSpriteTerrain(sprite))

  const engine: Engine = createMutable(createEngineForUI())
  // need sample player for UI engine
  const samplePlayerId = createMemo<number>(() => {
    return engine.state.players[0].id
  })

  bindEngineToComponent(engine)

  return (
    <EngineContext.Provider value={engine}>
      <div>
        <h2>
          <A href="/codex">Codex</A>{' - '}
          Terrains
        </h2>
        <table class="table table-sm">
          <thead>
            <tr>
              <td colspan="3" class="border-bottom-0" />
              <td colspan="4" class="text-center small text-muted">
                Movement Cost
              </td>
            </tr>
            <tr>
              <th colspan="2">Terrain</th>
              <th class="text-end">Defense</th>
              <th class="text-end">Foot</th>
              <th class="text-end">Tires</th>
              <th class="text-end">Treads</th>
              <th class="text-end">Helicopter</th>
              <th class="text-end">Income</th>
            </tr>
          </thead>
          <tbody>
            <For each={entityTypes} fallback={<div class="text-muted">Empty List</div>}>
              {(entityType: EntityTypeMeta) => {
                // const entDefaults = entityType.entDefaults
                // const mtype: Nullable<MoveTypeMeta> = getMoveTypeMetaById((entDefaults as HasMoveTypeId).mtype_id)
                const footCostPair = getMoveCostPair(entityType.id, MoveTypeId.Foot)
                const tireCostPair = getMoveCostPair(entityType.id, MoveTypeId.Tires)
                const treadCostPair = getMoveCostPair(entityType.id, MoveTypeId.Treads)
                const helicopterCostPair = getMoveCostPair(entityType.id, MoveTypeId.Helicopter)
                // console.log({ footCost, tireCost, treadCost, helicopterCost })

                return <tr>
                  <td>
                    <SingleSpriteIcon entity={createSampleEntity(entityType, samplePlayerId())} />
                  </td>
                  <td>
                    <A href={`/codex/terrain/${toSlug(entityType.dname)}`}>
                      {entityType.dname}
                    </A>
                  </td>
                  <td class="text-end">
                    {(entityType.defense != null)
                      ? entityType.defense
                      : NA()}
                  </td>
                  <td class="text-end">
                    {((footCostPair?.value as number) >= 0) ? footCostPair?.value : NA()}
                  </td>
                  <td class="text-end">
                    {((tireCostPair?.value as number) >= 0) ? tireCostPair?.value : NA()}
                  </td>
                  <td class="text-end">
                    {((treadCostPair?.value as number) >= 0) ? treadCostPair?.value : NA()}
                  </td>
                  <td class="text-end">
                    {((helicopterCostPair?.value as number) >= 0) ? helicopterCostPair?.value : NA()}
                  </td>
                  <td class="text-end">
                    {(entityType.income != null)
                      ? `$${entityType.income}`
                      : NA()}
                  </td>
                </tr>
              }}
            </For>
          </tbody>
        </table>
      </div>
    </EngineContext.Provider>
  )
}

export default CodexTerrainIndexPage
import type { Engine } from '../engine/Engine.type'
import type { HasTaxiID } from '../entity/HasTaxiID'
import type { HasHP } from '../has_hp'
import type { HasPlayerId } from '../player/has_player_id'
import isOrthogonallyAdjacent from '../tile_position_xy/isOrthogonallyAdjacent'
import { samePosition } from '../tile_position_xy/samePosition'
import createDraftMoveAttackEstimate from './createDraftMoveAttackEstimate'
import type { DraftMove_AttackEstimate } from './DraftMove_AttackEstimate.type'

export default function calculateDraftMoveAttackEstimates(engine: Engine): void {
  // console.log('calculateDraftMoveAttackEstimates')
  const { draftMove, state, hoveredPosition } = engine
  if (draftMove) {
    const estimates: Array<DraftMove_AttackEstimate> = []
    const { unit, destPosition, attackPosition } = draftMove
    const { player_id: unitPlayerId } = unit as HasPlayerId
    if (unitPlayerId) {
      const targetPosition = hoveredPosition || attackPosition || destPosition
      // || coord(unit.x, unit.y)
      if (targetPosition) {
        const { ents } = state
        let index = ents.length
        while (index--) {
          const entity = ents[index]
          if ((entity as HasTaxiID).taxi_id) {
            // cant attack units inside of transport
          } else if ((entity as HasPlayerId).player_id === unitPlayerId) {
            // cant attack your own units
          } else if ((entity as HasHP).hp > 0) {
            if (samePosition(entity, hoveredPosition)) {
              estimates.push(createDraftMoveAttackEstimate(engine, unit, destPosition || unit, entity))
            } else if (samePosition(entity, attackPosition)) {
              estimates.push(createDraftMoveAttackEstimate(engine, unit, destPosition || unit, entity))
            } else if (destPosition && isOrthogonallyAdjacent(entity, destPosition)) {
              estimates.push(createDraftMoveAttackEstimate(engine, unit, destPosition, entity))
            }
          }
        }
      }
    }
    draftMove.attackEstimates = estimates
  }
}

import type { HasAnnexPoints } from '../HasAnnexPoints.type'
import canUnitAnnexEntity from '../entity/canUnitAnnexEntity'
import type { Entity } from '../entity/index'
import type { EntityGridXY } from './EntityGridXY.type'
import createLazyReadOnlyGridXY from './createLazyReadOnlyGridXY'

type AnnexableList = Array<Entity & HasAnnexPoints>

export default function createLazyAnnexListGrid(
  // full state width
  gridWidth: number,
  // full state height
  gridHeight: number,
  // full state ents as GridXY
  grid: EntityGridXY,
  // unit attempting to enter a taxi
  unit: Entity
) {
  const lazyGrid = createLazyReadOnlyGridXY<AnnexableList>(
    gridWidth,
    gridHeight,
    function annexableListGridGetter(x: number, y: number): AnnexableList {
      const list : AnnexableList = []
      const positionStack = grid?.[x]?.[y]
      if (positionStack) {
        for (let index = 0; index < positionStack.length; index++) {
          const entity = positionStack[index];
          if (canUnitAnnexEntity(unit, entity)) {
            list.push(entity as Entity & HasAnnexPoints)
          }
        }
      }
      return list
    }
  )
  return lazyGrid
}

import { A } from '@solidjs/router'
import { Component } from 'solid-js'

const CodexIndexPage: Component = () => {

  // console.log('CodexIndexPage')

  return (
    <div>
      <h2>Simple Tactics - Codex</h2>
      <h2>
        <A href={"/codex/unit"}>Units</A>
      </h2>
      <h2>
        <A href={"/codex/terrain"}>Terrain</A>
      </h2>
      <h2>
        <A href={"/codex/damage-calculator"}>Damage Calculator</A>
      </h2>
    </div>
  )
}

export default CodexIndexPage

import { literal, object, type InferOutput } from 'valibot'
import { Engine } from '../../../../engine/Engine.type'
import { Player } from '../../../../player/Player.type'
import { PlayerId, PlayerIdSchema } from '../../../../player/PlayerId'
import resetPlayerSlotToOpen from '../../../../player/resetPlayerSlotToOpen'
import { ActionType } from '../ActionType'

export const KickPlayerSlotActionSchema = object({
  type: literal(ActionType.Lobby.KickPlayerSlot),
  player_id: PlayerIdSchema,
})

export type KickPlayerSlotAction = InferOutput<typeof KickPlayerSlotActionSchema>

export function createKickPlayerSlotAction(player_id: PlayerId): KickPlayerSlotAction {
  return {
    type: ActionType.Lobby.KickPlayerSlot,
    player_id,
  }
}

export async function handleKickPlayerSlotAction(
  engine: Engine,
  action: KickPlayerSlotAction
): Promise<void> {
  const { player_id } = action
  const is_local = !engine.state.online

  engine.state.players.forEach((player: Player): void => {
    if (player.id === player_id) {
      resetPlayerSlotToOpen(player)
      player.is_local = is_local
    }
  })
}

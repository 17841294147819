import { modifyMutable, produce } from 'solid-js/store'
import calculateMapWidthHeightPxTileSize from './calculateMapWidthHeightPx'
import calculateTileSize from './calculateTileSize'
import { Engine } from './engine/Engine.type'
import { abs, floor } from './util/math'
import { ZoomQueue } from './zoom_queue'

const MIN_DELTA_PER_MS_RATIO = 1

const zoomFactor = -0.05
const min_zoom = 25
const max_zoom = 400

function toSmallerPercent(carry: number, zq: ZoomQueue) {
  return carry + abs(zq.deltaY / 3)
}
const { now } = Date

export default function progressZoomQueue(engine: Engine, frameMs: number) {
  modifyMutable(
    engine,
    produce((engine: Engine) => {
      // console.log('progressZoomQueue', engine.viewCtx.zoomQueue.length)
      // if (engine.viewCtx.zoomQueue.length > 0) console.table(structuredClone(unwrap(engine.viewCtx.zoomQueue)))
      const { viewCtx } = engine
      const { zoomQueue } = viewCtx
      // const dirty = engine.cache[1]

      let totalFrameZoomDelta = 0
      let zq: ZoomQueue
      // console.log('progressZoomQueue.limit', limit)
      // console.log('progressZoomQueue.timeStamp', timeStamp)
      // console.log('progressZoomQueue.while', zq = zoomQueue[0], totalFrameZoomDelta, limit)
      if (zoomQueue[0]) {
        // let maxIterations = 100

        // const zoomPending = (zoomQueue.reduce((carry, zq)=>carry + abs(zq.deltaY), 0))
        const limit = floor(zoomQueue.reduce(toSmallerPercent, MIN_DELTA_PER_MS_RATIO * frameMs))
        let delta: number = 0
        // console.log('progressZoomQueue.limit', limit)
        while ((zq = zoomQueue[0]) && limit > totalFrameZoomDelta) {
          // if (!(maxIterations --> 0)) {
          //   console.error('progressZoomQueue.maxIterations!')
          //   throw new Error('progressZoomQueue.maxIterations!')
          // }
          const {
            clientX, clientY,
            deltaY,
            prevMapWidthPx, prevMapHeightPx,
            prevPanX, prevPanY,
          } = zq
          delta = deltaY
          if (delta === 0) {
            // console.log('zq.deltaY === 0')
            zoomQueue.shift()
            continue
          } else if (-delta > limit) {
            // console.log('-delta > limit')
            zq.deltaY += limit
            delta = -limit
          } else if (delta > limit) {
            // console.log('delta > limit')
            zq.deltaY -= limit
            delta = limit
          } else {
            // console.log('zoomQueue. use full delta')
            zoomQueue.shift()
          }

          totalFrameZoomDelta += abs(delta)

          const zoomDelta = delta * zoomFactor

          // console.log('viewCtx.zoom', viewCtx.zoom, 'zoomDelta', zoomDelta, 'limit', limit, 'zoomPending', zoomPending)
          let nextZoom = viewCtx.zoom + zoomDelta

          if (nextZoom > max_zoom) {
            nextZoom = max_zoom
          } else if (nextZoom < min_zoom) {
            nextZoom = min_zoom
          }
          viewCtx.zoom = nextZoom

          viewCtx.tile_size = calculateTileSize(viewCtx)
          calculateMapWidthHeightPxTileSize(engine)

          const { mapWidthPx: nextMapWidthPx, mapHeightPx: nextMapHeightPx } = viewCtx

          const ratioMapClientX = (clientX - prevPanX) / prevMapWidthPx
          const ratioMapClientY = (clientY - prevPanY) / prevMapHeightPx
          // console.table([{ratioMapClientX, ratioMapClientY, prevMapWidthPx, prevMapHeightPx}])

          const nextPanX = -((nextMapWidthPx * ratioMapClientX) - clientX)
          const nextPanY = -((nextMapHeightPx * ratioMapClientY) - clientY)
          // console.table([{nextPanX, nextPanY, prevPanX, prevPanY}])

          viewCtx.pan_x_f = nextPanX
          viewCtx.pan_y_f = nextPanY

          // floor the values so you don't get weird sub-pixel blurs
          viewCtx.pan_x = floor(nextPanX)
          viewCtx.pan_y = floor(nextPanY)

          // console.log('setting:', { tile_size: viewCtx.tile_size })
          // console.log('setting:', { pan_x: viewCtx.pan_x, pan_y: viewCtx.pan_y })
          // console.log('setting:', { mapWidthPx: viewCtx.mapWidthPx, mapHeightPx: viewCtx.mapHeightPx })

          // console.log('nextZoom', nextZoom)

          viewCtx.lastZoomAt = now()
          // dirty(EngineCache.ViewCtx)
        }
      }
    })
  )
}

import { literal, object, type InferOutput } from 'valibot'
import NotYourTurn from '../../../../../Exception/NotYourTurn.class'
import type { HasAttackedThisTurn } from '../../../../AttackedThisTurn'
import { Engine } from '../../../../engine/Engine.type'
import getPerTurnIncomeFromPlayerEnts from '../../../../entity/getPerTurnIncomeFromPlayerEnts'
import getPlayerEntities from '../../../../entity/getPlayerEntities'
import hasHealerInProximity from '../../../../entity/hasHealerInProximity'
import hasSupplierInProximity from '../../../../entity/hasSupplierInProximity'
import findByIdOrThrow from '../../../../findByIdOrThrow'
import { MAX_START_TURN_ADD_FUEL } from '../../../../fuel.type'
import { PlayerIdSchema, type PlayerId } from '../../../../player/PlayerId'
import { PlayerTurnStatus } from '../../../../player/PlayerTurnStatus'
import { floor, min } from '../../../../util/math'
import type { HasWaitedThisTurn } from '../../../../WaitedThisTurn'
import type { HasWasBuiltThisTurn } from '../../../../WasBuiltThisTurn'
import { ActionType } from '../ActionType'
import entityTypeMetaList from '../../../../entity/entityTypeMetaList.generated'

export const StartTurnActionSchema = object({
  type: literal(ActionType.Game.StartTurn),
  player_id: PlayerIdSchema,
})

export type StartTurnAction = InferOutput<typeof StartTurnActionSchema>

export function createStartTurnAction(player_id: PlayerId): StartTurnAction {
  // console.log('createStartTurnAction')
  return {
    type: ActionType.Game.StartTurn,
    player_id,
  }
}

// const fuelCostMult = 2

export async function handleStartTurnAction(
  engine: Engine,
  action: StartTurnAction
): Promise<void> {
  // console.log('handleStartTurnAction')
  // console.error(new Error('handleStartTurnAction'))
  const { player_id } = action
  const { state } = engine
  const { ents } = state
  const player = findByIdOrThrow(state.players, player_id)
  // console.log(
  //   'player.turn_status !== PlayerTurnStatus.Pending',
  //   player.turn_status !== PlayerTurnStatus.Pending,
  //   {
  //     'player.turn_status': player.turn_status,
  //     'PlayerTurnStatus.Pending': PlayerTurnStatus.Pending,
  //   }
  // )
  if (player.turn_status !== PlayerTurnStatus.Pending) {
    throw new NotYourTurn
  }
  const playerEnts = getPlayerEntities(ents, player_id)

  let credits = player.money

  credits += getPerTurnIncomeFromPlayerEnts(playerEnts)

  for (let index = playerEnts.length; index--; ) {
    const entity = playerEnts[index]
    const entityMeta = findByIdOrThrow(entityTypeMetaList, entity.etype_id)
    const { price, entDefaults } = entityMeta
    const {
      hp: defaultHp,
      fuel: defaultFuel,
      mobility: defaultMobility,
    } = entDefaults as {
      fuel?: number
      hp?: number
      mobility?: number
    }

    // charge player for healing
    if ('hp' in entity && price && defaultHp) {
      const { hp: prevHp } = entity
      if (entity.hp < defaultHp && hasHealerInProximity(state, entity)) {
        // console.log("'hp' in entity && price && defaultHp", entity)
        // console.log({ prevHp, defaultHp, hasHealerInProximity: hasHealerInProximity(state, entity) })
        const nextHp = min(defaultHp, prevHp + 2)
        const hpAdded = nextHp - prevHp
        const nextHpCreditCost = (hpAdded * price) / 10
        // console.log('nextHpCreditCost > credits', {nextHpCreditCost, credits, result: nextHpCreditCost > credits})
        // make sure they can afford it first
        if (credits >= nextHpCreditCost) {
          credits -= nextHpCreditCost
          entity.hp = nextHp
          // console.log('healing', { hpAdded, nextHp, nextHpCreditCost, credits })
        }
        // TODO else ran-out-of-money-for-energy signal
      }
    }

    // charge player for fuel
    if ('fuel' in entity && price && defaultFuel) {
      const { fuel } = entity
      if (entity.fuel < defaultFuel && hasSupplierInProximity(state, entity)) {
        entity.fuel = min(defaultFuel, fuel + MAX_START_TURN_ADD_FUEL)
        // const fuelAdded = entity.fuel - fuel
        // credits -= fuelAdded * fuelCostMult
      }
    }

    // turn is starting, ent was not built this turn
    delete (entity as HasWasBuiltThisTurn).builtThisTurn
    delete (entity as HasWaitedThisTurn).waitedThisTurn
    delete (entity as HasAttackedThisTurn).attackedThisTurn

    // reset mobility points
    if ('mobility' in entity && defaultMobility) {
      entity.mobility = defaultMobility
    }
  }

  player.money = floor(credits)
  player.turn_status = PlayerTurnStatus.Playing
}

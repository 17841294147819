import { AuthSession } from '@supabase/supabase-js'
import { AxiosResponse } from 'axios'
import { supabaseAxios } from '../../../axios'
import { fromEntries } from '../../../lib/core/util/Object'
import { emit, EventListenerList } from '../../../lib/event/event'
import ls from '../../../lib/localStorage'
import { Nullable } from '../../../typescript'
import VITE_BACKEND_JWT_LS_KEY from './auth_session_key'
import getAuthSessionFromLocalStorage from './getAuthSessionFromLocalStorage'

export const sessionValueUpdatedEvent: EventListenerList<Nullable<AuthSession>> = [(newValue) => {
  ls.setItem(VITE_BACKEND_JWT_LS_KEY, JSON.stringify(newValue))
}]

let sessionValue: Nullable<AuthSession> = getAuthSessionFromLocalStorage()

// export default sessionValue

export function getSessionValue() {
  return sessionValue
}

// get and parse this from the URL
// #access_token=example&expires_at=1714327071&expires_in=3600&refresh_token=e4bTpnc53e0px0DOesdMLQ&token_type=bearer&type=magiclink
// const hash = new URLSearchParams(window.location.hash.slice(1)).get('access_token')
const hash = fromEntries(new URLSearchParams(location.hash.slice(1)))
if (hash?.access_token) {
  const tmpSession = fromEntries(new URLSearchParams(location.hash.slice(1))) as Partial<AuthSession>
  supabaseAxios.get('/auth/v1/user', {
    headers: {
      Authorization: `Bearer ${tmpSession.access_token}`,
    },
  }).then((response: AxiosResponse) => {
    tmpSession.user = response.data
    updateSessionValue(tmpSession as AuthSession)

    // replaces current history item (user will have to go back to email to get link)
    history.replaceState(null, '', location.pathname + location.search);
    // adds next history item:
    // history.pushState(null, '', location.pathname + location.search);
  })
}

export function updateSessionValue(newValue: Nullable<AuthSession>): void {
  // console.log('updateSessionValue')
  sessionValue = newValue
  emit(sessionValueUpdatedEvent, newValue)
}


window.addEventListener('storage', function (event) {
  if (event.key === VITE_BACKEND_JWT_LS_KEY) {
    const newValue: Nullable<AuthSession> = event.newValue ? JSON.parse(event.newValue) : null
    updateSessionValue(newValue)
  }
})

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ActionType {
  export enum Lobby {
    // GameLobby Actions
    ClaimPlayerSlot = 500,
    SetBotPlayerSlot,
    ReservePlayerSlot,
    LeavePlayerSlot,
    KickPlayerSlot,
    InvitePlayerSlot,
    SwapPlayerSlot,
    PatchPlayerSlot,
    PatchConfig,
    StartNewGame,
  }
  export enum Map {
    // MapEditor Actions
    // intended for "Save"/"Revert" usage, at least in the short-term.
    SetState = 1000,
    PatchMap,
    PaintTile,
    EraseTile,
    AddPlayer,
    PatchPlayer,
    DeletePlayer,
    MapToLobby,
  }
  export enum Game {
    // GamePlay Actions
    StartRound = 2000,
    StartTurn,
    EndTurn,
    EndRound,

    PlaceFactoryOrder,
    CancelFactoryOrder,

    MoveUnit,
  }
}

export type ActionType = ActionType.Lobby | ActionType.Map | ActionType.Game

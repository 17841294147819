import { Signal, createRoot, createSignal } from 'solid-js'
import { addListener } from '../../../lib/event/event'
import { Nullable } from '../../../typescript'
import { profileValueUpdatedEvent } from './authProfileValue'
import { AuthProfile } from './auth_profile.type'
import getAuthProfileValueFromLocalStorage from './getAuthProfileValueFromLocalStorage'

addListener(profileValueUpdatedEvent, (newValue) => {
  signal[1](newValue)
})

const signal: Signal<Nullable<AuthProfile>> = createRoot(() => {
  // eslint-disable-next-line solid/reactivity
  const signal = createSignal<Nullable<AuthProfile>>(getAuthProfileValueFromLocalStorage())

  return signal
})

export default function createAuthProfileSignal() {
  return signal
}

import axios from "axios"
import { VITE_ALLOWS_GUEST_ACCESS_TOKEN } from "./auth_session_key"
import { updateSessionValue } from "./sessionValue"

export default async function fetchGuestAccessToken() {
  // console.log('fetchGuestAccessToken')
  if (VITE_ALLOWS_GUEST_ACCESS_TOKEN) {
    await new Promise(resolve => setTimeout(resolve, 10000))
    const response = await axios.post('/api/auth/access_token', {
      grant_type: 'guest',
    }, {
      headers: {
        Accept: 'application/json',
      },
    })
    // console.log('fetchGuestAccessToken', response.data)
    updateSessionValue(response.data)
    // TODO update JWT
    // throw new Error('Not Implemented')
  } else {
    throw new Error('Not Implemented (redirect to login)')
  }
}
